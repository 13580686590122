@import "../../../styles/variables.scss";

.Quote{
  //  background: rgb(155,101,102);
 //   background: linear-gradient(180deg, rgba(155,101,102,0) 0%, rgba(155,101,102,1) 30%, rgba(156,102,103,1) 50%, rgba(157,104,105,1) 70%, rgba(255,255,255,0) 100%);
    padding: 200px 0;
    display: flex;
    align-items: center;
    position: relative;

}

.Quote-content{
    position: relative;
    z-index: 2;
    width: 100%;
    div[class*='col']{
        padding: 0;
    }
}

.Quote-heading{
    text-align: end;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-right: -40px;
    position: relative;
    z-index: 2; 
}

.Quote-bg{
    background: #D9A68D;
    filter: blur(70px);
    height: 80%;
    position: absolute;
    width: 300%;
    z-index: 1;
    left: -100%;
    z-index: 1;
}

.Quote-image{
   // position: absolute;
   position: relative;
   z-index: 1;
   border-radius: 12px;
}

.Quote-sender{
    bottom: -22px;
    position: absolute;
    z-index: 2; 
    left: -35px;
}

.Quote-name{
    display: block;
}