@import "../../styles/variables.scss";
.Dashboard-header{
    margin-bottom: 50px;
}
.Dashboard-heading{
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 0;
    // &:after{
    //     content: "";
    //     margin-left: 20px;
    //     width: 1px;
    //     background: $colorPrimaryBlack;
    //     display: inline-block;
    //     height: 30px;
    // }
}
.Dashboard-subHeading{
    display: inline-block;
    font-size: $textSizeXXXLarge;
    font-family: $fontFamilySecondary;
}

.Dashboard-user{
    margin-bottom: 20px;
    span {
        font-size: $textSizeXXXLarge;
        font-weight: 700;
        font-family: $fontFamilyPrimary;
    }
}

.Dashboard-userImage{
    display: none;
   // display: inline-block;
    margin-right: 20px;
    img{
        width: 42px;
        height: 42px;
        border-radius: 100%;
    }
}

.Dashboard-welcomeUser {
    display: inline-block;
    font-size: $textSizeXXXLarge;
    font-weight: 400;
}

.Dashboard-banner{
    position: relative;
    .Dashboard-type{
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 100px;
        font-family: $fontFamilyPrimary;
        font-size: $textSizeXXXLarge;
        &:before{
            content: '●';
            margin-right: 8px;
        }
    }
    img{
        max-width: 100%;
        max-height: 200px;
        width: 100%;
        height: auto;
        border-radius: 100px;
        object-fit: cover;
    }
}
.Dashboard-StatisticsBtn{
    width: 32px;
    height: 32px;
    background: $colorWhite;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 100%;   
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;    
    justify-content: center;
    position: absolute;
    transition: .3s ease-in-out;
    cursor: pointer;
    border: none;
    i{
        color: $colorTextDisabled;
        font-size: 18px;
    } 
    &:hover{
        background: $colorTextDisabled;
      
        i{
            color: $colorWhite;
        }
    }
}
// Html removed, see the markup down on this page
// .Dashboard-settings{ 
//     display: flex;
//     justify-content: end;
//     .Dashboard-settings-icon{
//         color: $colorWhite;
//         font-size: 24px;
//         position: relative;
//         &:first-child{
//             margin-right: 20px;
//         }
//     }
//  .Dashboard-settings-notify{
//     min-width: 12px;
//     min-height: 12px;
//     border-radius: 100%;
//     aspect-ratio: 1;
//     background: $colorRed;
//     position: absolute;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     top: 0;
//     right: -5px;
//  }
//  .Dashboard-settings-notify-amount{
//      font-size: 10px;
//  }
// }

.Dashbord-hero{
    padding: 100px 0;
    // background: $colorCorduroy;
    background: $colorOffWhite !important;
    color: $colorPrimaryBlack;
}
//

.Dashbord-sectionHeading{
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 40px;
}

   
//     <Col xs={2}>
//     <div className='Dashboard-settings'>
//         <a className='Dashboard-settings-icon' href='#'><i className='icon-Envelope'></i>
//              <div className='Dashboard-settings-notify'><span className='Dashboard-settings-notify-amount'>1</span></div>
//         </a>
//         <a className='Dashboard-settings-icon' href='#'><i className='icon-Settings'></i></a>
//     </div>
// </Col>

.dashboard-page {
    .content {
        font-size: 22px;
        margin-top: 40px;
        margin-bottom: 60px;
    }

    .custom-card {
        cursor: pointer;
        position: relative; 
        border-radius: 20px 0px 0px 20px; 
        background-color: $colorOffWhite; 
        padding: 25px 0px 25px 0px;
        margin-right: 30px;
        margin-bottom: 10px;
        border: none; 
        border-left: 20px solid $colorPrimaryBlack;
        overflow: hidden; 
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .triangle {
        position: absolute; 
        top: 0; 
        right: 0;
        width: 0; 
        height: 0; 
        background: $colorWhite;
        border-left: 33px solid $colorOffWhite;
    }
}

  
  
  
