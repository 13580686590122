@import "../../../styles/variables.scss";
.Heading{

}

h1, .Heading--h1{
    font-size: 36px;
    line-height: 120%;
    letter-spacing: 0.02rem;
    font-family: $fontFamilyPrimary;
    font-weight: 600;
    text-transform: uppercase;
}

h2, .Heading--h2{
    font-size: 22px;
    line-height: 120%;
    font-family: $fontFamilyPrimary;
    font-weight: 600;
    text-transform: uppercase;
}

h3, .Heading--h3{
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    letter-spacing: 0.004em;
    font-family: $fontFamilyPrimary;
    text-transform: uppercase;
}

h4, .Heading--h4{
    font-weight: 600;
    font-size: 22px;
    line-height: 130%;
    letter-spacing: 0.003em;
    text-transform: uppercase;
    font-family: $fontFamilyPrimary;
}

h5, .Heading--h5{
    font-weight: 600;
    font-size: 22px;
    line-height: 133%;
    letter-spacing: 0.005em;
    font-family: $fontFamilyPrimary;
    text-transform: uppercase;
}

h6, .Heading--h6{
    font-weight: 600;
    font-size: 15px;
    line-height: 140%;
    letter-spacing: 0.01em;
    font-family: $fontFamilyPrimary;
    text-transform: uppercase;
}

h7, .Heading--h7{
    font-size: 15px;
    line-height: 140%;
    letter-spacing: 0.015em;
    font-family: $fontFamilyPrimary;
}

