@import "../../../styles/variables.scss";
.Breadcrumb{
    padding: 0;
}

.Breadcrumb-item{
    display: inline-block;
    list-style: none;
    font-size: $textSizeXSmall;
    color: $colorWhite;
    text-transform: uppercase;
    font-family: $fontFamilyPrimary;
}

.Breadcrumb-link{
    display: block;
    color: $colorWhite;
    &:after{
        content: "\e903";
        margin: 0 8px;
        font-family: $fontFamilyIcon;
        font-size: $textSizeXSmall;
        color: $colorWhite!important;
    }
    &:hover{
        
    }
}
.Breadcrumb-current{
    font-weight: 400;
}