@import '../../../../styles/variables.scss';
.Sizes{
    .Hero{
        padding: 50px 0;
        h1{
            color: $colorPrimaryBlack;
        }
    }
    .Button{
        i{
            padding-right: 8px;
        }
        margin-bottom: 10px;
    }
    .placeholderImage{
        margin-bottom: 20px;
        img {
            border-radius: 29.2px;
        }
    }
    .heading{
        font-size: 18px;
        line-height: 125%;
        letter-spacing: 0.01em;
        font-weight: 600;
        font-family: $fontFamilySecondary;
    }

    .custom-file-input {
        color: transparent;
    }
    .custom-file-input::-webkit-file-upload-button {
        visibility: hidden;
    }
    .custom-file-input::before {
        content: 'Upload excel file';
        color: white;
        display: inline-block;
        background: $colorPrimaryBlack;
        border: 1px solid #999;
        border-radius: 3px;
        padding: 10px 40px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        // text-shadow: 1px 1px #fff;
        font-weight: 700;
        font-size: 10pt;
        border-radius: 29.2px;
    }
    .custom-file-input:hover::before {
        border-color: black;
    }
    .custom-file-input:active {
        outline: 0;
    }
    .custom-file-input:active::before {
        background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
    }
}