@import "../../styles/variables.scss";
.Onbording{
    padding-top: 50px;
    padding-left: 140px;
    // overflow: hidden;
}

.Onboarding-slideWrapper{
    overflow: auto;

    .Onboarding-slide{
       // margin-left: calc(-50vw + 50%);
       // margin-right: calc(-50vw + 0%);
       white-space: nowrap;
        overflow: auto;
        padding: 2rem 0;
        cursor: pointer;
     }

    .CategoryTeaser{
        display: inline-flex;
        margin-right: 1.5rem; 
        white-space: normal;
    }
    /* width */
::-webkit-scrollbar {
    height: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: $colorStone1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
   
    background: $colorPrimaryBlack;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $colorBlack;
  }
}