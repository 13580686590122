@import '../../../styles/variables.scss';
.Sizes{
    .Hero{
        padding: 50px 0;
        h1{
            color: $colorPrimaryBlack;
        }
    }
    .Button{
        margin-top: 20px;
        .icon-Plus{
            padding-right: 8px;
        }
    }
    .heading{
        font-size: 18px;
        line-height: 125%;
        letter-spacing: 0.01em;
        font-weight: 600;
        font-family: $fontFamilySecondary;
    }
    .css-1s2u09g-control, .css-1pahdxg-control {
        border-radius: 4px !important;
    }
    .sizesTextBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 16px;
        margin: 10px 10px 10px 0;
        width: 165px;
        height: 51px;
        left: 334px;
        top: 475px;
        background: $colorSnow;
        border: 0.6px solid #222222;
        border-radius: 4px;
        &.errorBorder {
            border: 0.6px solid $colorRed;
        }
    }
    .sizeDelete{
        cursor: auto !important;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 27px 10px 10px 0;
        width: 165px;
        height: 51px;
        left: 334px;
        top: 475px;
        i {
            cursor: pointer;
        }
    }
    .addRegion {
        span {
            font-weight: normal;
            cursor: pointer;
        }
    }
    .addSize {
        padding: 10px;
        span {
            cursor: pointer;
        }
        
    }
    .sizesTable {
        // max-height: 500px;
        overflow: auto;

        &::-webkit-scrollbar {
            height: 6px;              
            width: 6px;      
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
        background: $colorStone3; 
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
        background: $colorGray; 
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
        background: $colorDarkGray; 
        }
        table{
            white-space: nowrap;
            border-collapse: separate;
            border-spacing: 0;
        }
        .regionDelete {
            margin-right: 10px;
            cursor: pointer;
            text-align: center;
        }
    }
    .error {
        color: $colorRed;
        height: 1px;
        text-align: left;
    }
    .errorBorder {
        .css-1s2u09g-control, .css-1pahdxg-control {
            border-color: $colorRed !important;
        }
    }
    .regionDropdown{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 10px 10px 10px 0;
        .css-1s2u09g-control, .css-1pahdxg-control,.css-1insrsq-control {
            background: $colorSnow;
            border: 0.6px solid #222222;
            border-radius: 4px !important;
            width: 165px;
            height: 51px;  
        }
        .css-109onse-indicatorSeparator {
            display: none;
        }
    }
}