@import "../../styles/variables.scss";
.partner-user {
    background-color: $colorOffWhite;
    // .nav-tabs {
    //     border-bottom: 1px solid $colorWhite;
    //     .nav-link {
    //         font-family: HM Sans;
    //         font-style: normal;
    //         font-weight: initial;
    //         font-size: 15px;
    //         line-height: 130.02%;
    //         text-align: center;
    //         letter-spacing: 0.015em;
    //         color: $colorWhite;

    //         &:focus, &:hover {
    //             border-color: transparent;
    //         }
    //     }

    //     .active {
    //         color: $colorWhite;
    //         background: none;
    //         border-color: transparent;
    //         border-bottom: 2px solid $colorWhite !important;
    //         font-weight: 600;
    //     }
    // }
}