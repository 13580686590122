@import "../../styles/variables.scss";
.add-users {
    position: fixed;
    bottom: 20px;
    left: 50%;
    z-index: 100;
    cursor: pointer;
}
.partnerUser-tab {
    .add-users {
        span {
            color: $colorBlack;
            text-transform: uppercase;
            font-family: $fontFamilyPrimary;
        }
    }
    .userActive {
        cursor: pointer;
        background: #d9d7d4;
        &:last-child td:first-child {
            border-bottom-left-radius: 8px;
            border-top-left-radius: 8px;
        }
        td .Button {
            opacity: 1;
        }
    }
}