@import "../../styles/variables.scss";
@media print {
    .printHidden{
        display: none;
    }

    img {
        display: block;
        break-inside: avoid;
    }
}

.articlePage {
    .articleHeading {
        font-family: $fontHMSlussenExpandedBold;
    }
    .container {
        padding: 0 70px;
    }
    b, strong {
        font-weight: bold;
    }
    .ArticleText {
        font-family: $fontFamilySecondary;
        font-size: $textSizeLarge;
    }
}