@import '../../styles/variables.scss';
.Sizes{
    .Hero{
        padding: 50px 0;
        h1{
            color: $colorPrimaryBlack;
        }
    }
    .Button{
        .icon-Plus{
            padding-right: 8px;
        }
    }
    .sizeItem{
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        height: 57px;
        background: $colorWhite;
        border-radius: 4px; 
        margin-bottom: 5px;  
        .status{
            padding: 4px 8px;
            margin-right: 20px;
            height: 32px;
            border-radius: 4px;
            background: #EBEBEB;
            &.success{
                background: #CEEBCC;
            }
            &.error{
                background: #FFBEBE;
            }
        }
    }
    .message{
        justify-content: center;
        p {
            background: $colorValid;
            width: 100%;
            border-radius: 10px;
            color: $colorPrimaryBlack;
            padding: 8px 16px;
            font-weight: 900;
        }
    }
}