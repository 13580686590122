@import '../../styles/variables.scss';
.Upload{
    position: relative;

    .customDatepickerInput {
        border-radius: 8px;
        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
        border: none;
        padding: 0 14px 0 14px;
        text-align: left;
    }

    .nav-tabs {
        border-bottom: 1px solid $colorOffWhite;
        margin-bottom: 20px;

        .nav-link {
            transition: 0.1s ease-in-out;
            display: inline-flex;
            height: 44px;
            justify-content: center;
            align-items: center;
            border: 1px solid #222;
            border-radius: 37px;
            color: #222222;
            padding: 0 38px;
            font-size: 15px;
            font-family: $fontFamilyPrimary;
            letter-spacing: -0.02rem;
            line-height: 0;
            margin-right: 10px;
        }

        .active {
            color: #ffffff !important;
            background: #222 !important;
        }
    }
    .css-1s2u09g-control, .css-1pahdxg-control, .css-1insrsq-control{
        border-radius: 8px !important;
        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10) !important;
        border: none;
        padding: 0 6px 0 6px !important;
    }
    .discardButton {
        color: $colorRed;
        border-color: $colorRed;
        &:hover {
            background-color: $colorRed;
            color: $colorWhite;
        }
    }
    .listContainer {
        border-top: 2px solid $colorOffWhite;
        padding: 0px 50px 50px 50px;
    }
    .uploadContainer {
        padding: 52px;
        .fileError {
            font-style: italic;
            font-weight: 400;
            line-height: normal;
            color: $colorRed;
            animation: shake 2s ease-in-out, fadeIn 1s ease-in-out;
            opacity: 1;
        }
  
        @keyframes fadeIn {
            from {
            opacity: 0;
            }
            to {
            opacity: 1;
            }
        }
  
        @keyframes shake {
            0%, 100% {
            transform: translateX(0);
            }
            25% {
            transform: translateX(-5px);
            }
            50% {
            transform: translateX(5px);
            }
            75% {
            transform: translateX(-5px);
            }
        }
    }
    // .css-1pahdxg-control { 
    //     overflow: visible !important; 
    // }
    
    // .css-26l3qy-menu {
    //     max-height: 130px; 
    //     overflow: auto; 
    // }
    
    // .css-4ljt47-MenuList { 
    //     max-height: 130px; 
    //     overflow-y: auto; 
    // }

    [data-tooltip] {
        position: absolute;
        top: -5px;
        right: 24%;
        cursor: pointer;
        text-align: center;
    }
    
    [data-tooltip]:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent; 
        border-right: 6px solid transparent; 
        border-bottom: 6px solid transparent;
        border-top: 6px solid $colorWhite;
        top: -10px;
        left: calc(50% - 6px); 
        opacity: 0;
        transition: opacity 0.3s;
        visibility: hidden;
        z-index: 999999;
    }

    [data-tooltip]:after {
        content: attr(data-tooltip);
        position: absolute;
        background: $colorWhite;
        color: $colorDarkGray;
        padding: 10px;
        white-space: pre-wrap; 
        display: block;
        -webkit-box-orient: vertical;
        opacity: 0;
        transition: opacity 0.3s;
        bottom: calc(100% + 10px); 
        left: 50%;
        transform: translateX(-50%);
        width: 156px;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1); 
        font-size: 12px;
        z-index: 99999;
        visibility: hidden;
        font-weight: 400;
        font-family: $fontFamilySecondary;
        text-transform: none;
    }
    
    [data-tooltip]:hover:after,
    [data-tooltip]:hover:before {
        opacity: 1;
        transition: all 0.1s ease 0.5s;
        visibility: visible;
    }
}

.Upload-box{
    background: $colorWhite;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius:24px 24px 0 0;
    padding-top: 1px;
    // padding: 50px 50px;
    // overflow: auto;
    position: relative;
    min-height: 350px;
    // overflow-x: hidden;
    &.Upload-box--rounded{
        border-radius:24px 24px
    }
    &.Upload-box--scroll{
        // max-height: 75vh;
    }
}

.Upload-ctaTop{
    position: absolute;
    right: 20px;
    .Button{
        margin-right: 10px;
    }
    input[type='checkbox']{
        margin-right: 5px;
    }
}

.Upload-ApprovedBtn-notice{
    &:after{
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: $colorRed;
        margin-left: 12px; 
    }
}

.confirmDeletePopUp {
    .modal-dialog {
        max-width: 406px !important;
    }  
}

.discardButton {
    color: $colorRed;
    border-color: $colorRed;
    &:hover {
        background-color: $colorRed;
        color: $colorWhite;
    }
}

.reUploadPopUp {
    .modal-dialog {
        max-width: 400px !important;
    } 
    .reUploadContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        .ProductAdd {
            width: 65%;
        }
    }
    .fileError {
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        color: $colorRed;
        animation: shake 2s ease-in-out, fadeIn 1s ease-in-out;
        opacity: 1;
    }
}

.successPopup {
    .modal-dialog {
        max-width: 400px !important;
    } 
}

