@import "../../../styles/variables.scss";
.invoiceList-page {
    padding-bottom: 30px;
    .right-inner-addon {
        i {
            padding: 18px 18px;
        }
    }
    .form-control {
        border: 1px solid $colorBlack;
        color: $colorBlack;
        border-radius: 50px;
        min-height: 51px !important;
        padding: 10px;
        padding-left: 30px;
    }
    background-color: $colorOffWhite;
    .users-table table tr td .Button {
        opacity: 1;
    }
    .css-1s2u09g-control, .css-1pahdxg-control {
        border: 1px solid $colorBlack !important;
    }
    .filterLabel{
        background: $colorOffWhite;
        margin-right: 10px;
        padding: 3px 12px 3px 12px;
        cursor: default;
        border-radius: 0px;
        color: $colorBlack;
        .icon-Close {
            font-size: 12px;
            cursor: pointer;
            margin-right: 5px;
            color: $colorRed;
        }
    }
    .clearAll {
        background: $colorBlack; 
        padding: 3px 12px 3px 12px;
        margin-right: 10px;
        color: $colorWhite;
        border-radius: 0px;
    }
    
    [data-tooltip] {
        position: relative;
        cursor: pointer;
        img {
            margin-top: -5px;
        }
    }
    
    [data-tooltip]:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent; 
        border-right: 6px solid transparent; 
        border-bottom: 6px solid transparent;
        border-top: 6px solid $colorDarkGray;
        top: -5px;
        left: calc(50% - 8px); 
        opacity: 0;
        transition: opacity 0.3s;
        visibility: hidden;
    }

    [data-tooltip]:after {
        content: attr(data-tooltip);
        position: absolute;
        background: $colorWhite;
        color: $colorDarkGray;
        padding: 5px;
        white-space: pre-wrap; 
        overflow: hidden;
        display: -webkit-box;
        // -webkit-line-clamp: 4;
        //         line-clamp: 4; 
        -webkit-box-orient: vertical;
        opacity: 0;
        transition: opacity 0.3s;
        bottom: calc(100% + 5px); 
        left: 50%;
        transform: translateX(-50%);
        width: 200px;
        box-shadow: 0px 4px 15px 0px #00000040;
        font-size: 12px;
        font-style: italic;
        z-index: 99999;
        visibility: hidden;
        border-radius: 10px;
        overflow: auto;
        max-height: 150px;
    }
    
    [data-tooltip]:hover:after,
    [data-tooltip]:hover:before {
        opacity: 1;
        transition: all 0.1s ease 0.5s;
        visibility: visible;
    } 
    
    .commentIcon {
        width: 18px;
        height: 18px;
    }
}