@import "../../../styles/variables.scss";

.popup-backdrop {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 110;
    top: 0;
    left: 0;

    .addUser-popup {
        width: 100%;
        height: 100vh;
        background: #fff;
        float: right;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        max-width: 420px;
        position: relative;

        &--header {
            // padding: 19px 27px;
            // border-bottom: 0.5px solid $colorBlack;

            h6 span{
                float: right;
                cursor: pointer;
            }
        }

        &--body {
            padding: 40px 27px;
            min-height: calc(100vh - 155px);
            max-height: calc(100vh - 155px);
            overflow-y: auto;

            span {
                font-family: $fontFamilySecondary;
                font-style: normal;
                font-weight: normal;
                font-size: $textSizeLarge;
                line-height: 20px;
                /* identical to box height, or 167% */

                display: flex;
                align-items: center;

                /* Action/Invalid */

                color: #FF7777;
                padding-left: 21px;
                position: relative;
                bottom: 10px;
                margin-bottom: 5px;
            }

            .form-control {
                
                padding: 0px 12px 0px 20px;

                width: 100%;
                height: 51px;
                border: 0.6px solid $colorPrimaryBlack;
                box-sizing: border-box;
                border-radius: 40px;
                margin-bottom: 15px;
                font-family: $fontFamilySecondary;
                font-style: normal;
                font-weight: normal;
                font-size: $textSizeLarge;
                line-height: 140%;
                /* identical to box height, or 21px */

                letter-spacing: 0.015em;

                /* H&M Text/Secondary */

                color: rgba(0, 0, 0, 0.65);
            }

            .error-control {
                border-color: #FF7777 !important;
            }

            .optimizely-access {
                font-family: $fontFamilySecondary;
                font-style: normal;
                font-weight: normal;
                font-size: $textSizeLarge;
                line-height: 160%;
                letter-spacing: 0.02em;
                color: $colorBlack;

                input {
                    margin-right: 5px;
                }
            }

            &--notification {
                // width: 271px;
                display: block;
                margin: auto;
                margin-top: 35%;
                text-align: center;

                h3 {
                    max-width: 250px;
                    display: block;
                    margin: auto;
                    margin-bottom: 30px
                }
                p {
                    font-family: $fontFamilySecondary;
                    font-style: normal;
                    font-weight: normal;
                    font-size: $textSizeLarge;
                    line-height: 160%;
                    /* or 24px */

                    letter-spacing: 0.01em;

                    color: $colorPrimaryBlack;
                }
            }

            &--delete-user {
                .delete-box {
                    background: #FAF9F8;
                    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
                    border-radius: 50px;
                    padding: 18px 23px;
                    margin-top: 30px;
                    
                    h6 {
                        margin-bottom: 0;
                        line-height: 20px; 
                        p {
                            font-size: $textSizeLarge;
                        }
                    }

                    img {
                        border-radius: 100%;
                        max-height: 40px;
                        max-width: 40px;
                    }

                    input {
                        margin-top: 12px;
                    }
                }
            }
        }

        &--footer {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            padding: 19px 27px;
            border-top: 0.5px solid $colorBlack;

            button {
                width: 100%;
            }
        }
    }
}