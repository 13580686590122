
@import "../../../styles/variables.scss";

.Breadcrumb-link {
    cursor: pointer;
}

.displayLineItems {
    padding: 30px 0px;
    background-color: $colorOffWhite;
    .exportContainer{
        margin-left: -10px;
        .export {
            margin-left: 10px;
            cursor: pointer;
        }
    }
}