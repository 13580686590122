@import "../../../styles/variables.scss";

.Header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    width: 100%;


    .Header-logo{
        // max-width: 135px;
        // height: 26px;

        &.Header-logo--white{
            display: none;
        }
    }

    .Header-actions{
        *{
            &:last-child{
                margin-left: 30px;
            }
        }
    }


    &--inverted{
        .Header-logo{
            // max-width: 135px;
            &.Header-logo--black{
                display: none;
            }
            &.Header-logo--white{
                display: block;
            }
        }
        .Link{
            color: $colorWhite;
        }
    }
}