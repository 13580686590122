@import "../../styles/variables.scss";
$innerRadius: 12px;

// .ArticleHero{
//     padding: 50px 0 200px;
// }
.Article-subHeading {
    margin-bottom: 32px;
}


.Table {
    position: relative;
    background: $colorHome1;
    border: 9px solid $colorHome1;
    border-radius: 12px;
  
    table {
      width: 100%;
      margin-top: -24px;
      border: none;
  
      thead {
        position: relative;
        top: -20px;
      }
      tbody {
      }
      th {
        padding-left: 20px;
        font-size: $textSizeLarge;
        font-weight: 400;
      }
      tr {
        position: relative;
        &:first-child {
          td {
            &:nth-child(2) {
              border-radius: $innerRadius 0 0 0;
            }
            &:last-child {
              border-radius: 0 $innerRadius 0 0;
            }
          }
        }
        &:last-child {
          td {
            &:nth-child(2) {
              border-radius: 0 0 0 $innerRadius;
            }
            &:last-child {
              border-radius: 0 0 $innerRadius 0;
            }
            border-bottom: none;
          }
        }
      }
      td {
        font-size: $textSizeLarge;
        padding: 20px;
        background: $colorSnow;
        text-align: center;
        border-bottom: 1px solid $colorStone2;
        border-right: 1px solid $colorStone2;
  
        &:first-child {
          font-size: $textSizeLarge;
          background: $colorHome1;
          color: $colorWhite;
          text-align: left;
          border-color: $colorGray;
          border-right: none;
          span {
            border-color: $colorGray;
          }
        }
        &:last-child {
          border-right: none;
        }
        span {
          display: block;
        }
      }
    }
  }
  
  /////
  
.Table-plain {
table {
    width: 100%;
}

thead {
    border-bottom: 1px solid $colorCopperRose;
}

tr {

    th {
    text-transform: uppercase;
    color: $colorOrchid;
    font-size: $textSizeLarge;
    letter-spacing: 0.03em;
    
    }


    td {
    font-size: $textSizeLarge;
    letter-spacing: 0.015em;
    line-height: 130%;
    font-weight: 400;
    padding: 0.8rem 0.5rem;
    transition: .3s ease-in-out;
    a{
        word-break: break-all;
        display: block;
        color: $colorBlack;
        &:hover{
        text-decoration: underline;
        color: $colorBlack;
        }
    }
    }
    &:hover {
    &:first-child td:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    &:first-child td:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    &:last-child td:first-child {
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
    }
    &:last-child td:last-child {
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
    }

    td {
        background: $colorSnow;
        .Button {
        opacity: 1;
        }
    }
    }
    &.Stocklist-outofstock{
        td{
            color: $colorGray;
            &:last-child{
                color: $colorRed;
            }
            
        }
    }
}
}
  
  
  //// Articletable
  
.Article {
table {
    width: 100%!important;
    position: relative;
    background: $colorOffWhite;
    border-radius: 12px;
    display: block;
    padding: 10px;
    border: none;
    height: 100%!important;
    overflow-x: auto;
    cursor: pointer;

    &::-webkit-scrollbar {
      height: 8px;
    }
      
    &::-webkit-scrollbar-track {
      background: $colorLightGrey;
    }
      
    &::-webkit-scrollbar-thumb {
        background: $colorBlack;
        border-radius: 5px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
      background: $colorBlack;
    }

    thead {
    position: absolute;
    top: -30px;
    width: 100%;
    
    tr{
        width: 100%;
        display: inline-flex;
    }
    
    }
    th {
    padding-left: 0px;
    font-size: $textSizeLarge;
    font-weight: 400;
    &:first-child{
        // padding-left: 0;
    // min-width: 200px;
        padding-left: 20px;
    }
    }


    tbody {
    width: 100%;
    display: inline-table;
    }
    tr {
    position: relative;
    &:first-child {
        td {
        &:nth-child(2) {
            border-radius: $innerRadius 0 0 0;
        }
        &:last-child {
            border-radius: 0 $innerRadius 0 0;
        }
        }
        // &:first-child{
        //   height: 18px;
        //   position: absolute;
        //   top: -81px;
        //   width: 100%;
        //   background: yellow;
        //   display: inherit;
        // }
    }
    &:last-child {
        td {
        &:nth-child(2) {
            border-radius: 0 0 0 $innerRadius;
        }
        &:last-child {
            border-radius: 0 0 $innerRadius 0;
        }
        border-bottom: none;
        }
    } 
    }
    td {
    font-size: $textSizeLarge;
    text-align: left !important;
    padding: 20px;
    background: $colorWhite;
    text-align: center;
    border-bottom: 1px solid $colorLightGrey;
    border-right: 1px solid $colorLightGrey;
    a{
        word-break: break-all;
        display: block;
        color: $colorBlack;
        &:hover{
        text-decoration: underline;
        color: $colorBlack;
        }
    }
    
    // width: auto!important;

    &:first-child {
        font-size: $textSizeLarge;
        background: $colorOffWhite;
        color: $colorBlack;
        text-align: left;
        border-color: $colorLightGrey;
        font-family: $fontFamilyPrimary;
        border-right: none;
        min-width: 200px;
        text-transform: uppercase;
        font-weight: 600;
        span {
        border-color: $colorLightGrey;
        }
    }
    &:last-child {
        border-right: none;
    }
    span {
        display: block;
    }
    }
}
}
  
.ArticleRowColor {
    table {
      width: 100%!important;
      position: relative;
     // background: $colorHome1;
      border-radius: 12px;
      display: block;
      padding: 10px;
      border: none;
      height: 100%!important;
     // overflow-x: auto;
  
      thead {
        position: absolute;
        top: -30px;
        width: 100%;
      
        tr{
          width: 100%;
          display: inline-flex;

        }
        
      }
      th {
        padding-left: 0px;
        font-size: $textSizeLarge;
        font-weight: 400;
        &:first-child{
         // padding-left: 0;
        // min-width: 200px;
         padding-left: 20px;
        }
      }
  
  
      tbody {
        width: 100%;
        display: inline-table;
      }
      tr {
        position: relative;
        &:first-child {
          td {
            font-family: $fontFamilyPrimary;
            background-color: $colorOffWhite;
            color: $colorBlack;
            font-size: $textSizeLarge;
            &:nth-child(2) {
              border-radius: $innerRadius 0 0 0;
            }
            &:last-child {
              border-radius: 0 $innerRadius 0 0;
            }
            text-transform: uppercase;
            font-weight: 600;
          }
          // &:first-child{
          //   height: 18px;
          //   position: absolute;
          //   top: -81px;
          //   width: 100%;
          //   background: yellow;
          //   display: inherit;
          // }
        }
        &:last-child {
          td {
            &:nth-child(2) {
              border-radius: 0 0 0 0  !important;
            }
            &:last-child {
              border-radius: 0 0 $innerRadius 0;
            }
            border-bottom: none;
          }
        } 
      }
      td {
        font-size: $textSizeLarge;
        padding: 20px;
        background: $colorSnow;
        text-align: center;
        border-bottom: 1px solid $colorLightGrey;
        border-right: 1px solid $colorLightGrey;
        a{
          word-break: break-all;
          display: block;
          color: $colorBlack;
          &:hover{
            text-decoration: underline;
            color: $colorBlack;
          }
        }
       
       // width: auto!important;
  
        &:first-child {
          font-family: $fontFamilySecondary;
          font-size: $textSizeLarge;
          background: $colorSnow;
          color: black;
          text-align: left;
          text-transform: none;
          font-weight: 400;
         // border-color: $colorGray;
          border-right: none;
          min-width: 200px;
          border-bottom: 1px solid $colorLightGrey;
          border-right: 1px solid $colorLightGrey;
          span {
            border-color: $colorGray;
          }
        }
        &:last-child {
          border-right: none;
        }
        span {
          display: block;
        }
      }
    }
  }