@import "../../../styles/variables.scss";
$ButtonDisabled: #9B9890;
$iconSpaceing: 10px;
.Button{
    transition: .3s ease-in-out;
    background: $colorPrimaryBlack;
    display: inline-flex;
    height: 44px;
    justify-content: center;
    align-items: center;
    border: 1px solid $colorPrimaryBlack;
    border-radius: 37px;
    color: $colorWhite;
    padding: 0 38px;
    font-size: $textSizeLarge;
    font-family: $fontFamilyPrimary;
    text-transform: uppercase;
    letter-spacing: -0.02rem;
    line-height: 0;
    &:disabled, &.is-disabled{
        background: $colorOffWhite;
        color: $colorBlack;
        border-color: $colorBlack;
        opacity: 0.5;
        &:hover{
            background: $colorOffWhite;
            color: $colorBlack;
            border-color: $colorBlack;
        }
        &.Button--arrow{
            &:hover{
                &:after{
                    left: $iconSpaceing;
                }
            }
        }
    }
    &.Button--outlined{
        color: $colorPrimaryBlack;
        &:hover{
            background: $colorPrimaryBlack;
            color: $colorWhite;
        }
        &.Button--ghost{

        }
        &:disabled, &.is-disabled{
            background: transparent;
            color: $ButtonDisabled
        }
    }   
    &:hover{
        background: transparent;
        border-color: $colorPrimaryBlack;
        color: $colorPrimaryBlack;
        text-decoration: none;
    }
    &--large{
        height: 56px;
        border-radius: 56px;
        font-size: $textSizeXLarge;
        min-width: 174px;
    }
    &--small{
        height: 28px;
        border-radius: 19px;
        padding: 0 30px;
        font-size: $textSizeXSmall;
    }

    &--white{
        background: $colorWhite;
        border-color: $colorWhite;
        color: $colorPrimaryBlack;
        &:hover{
            background: transparent;
            border-color: $colorWhite;
            color: $colorWhite;
        }
        &.Button--whiteOutlined{
            border-color: $colorBlack;
            &:hover{
                background: $colorSnow;
                color: $colorPrimaryBlack;
            }
        }

        &.Button--outlined{
            color: $colorWhite;
            &:hover{
                background: $colorWhite;
                color: $colorPrimaryBlack;
            }
           
        }
    }

    &--offWhite{
        background: $colorOffWhite;
        border-color: $colorPrimaryBlack;
        color: $colorPrimaryBlack;
        &:hover{
            background: $colorPrimaryBlack !important;
            border-color: $colorWhite;
            color: $colorWhite;
        }
        &.Button--whiteOutlined{
            border-color: $colorBlack;
            &:hover{
                background: $colorSnow;
                color: $colorPrimaryBlack;
            }
        }

        &.Button--outlined{
            color: $colorWhite;
            &:hover{
                background: $colorWhite;
                color: $colorPrimaryBlack;
            }
           
        }
    }

    &--transparentBlack{
        background: transparent;
        border-color: $colorPrimaryBlack;
        color: $colorPrimaryBlack;
        &:hover{
            background: $colorPrimaryBlack;
            border-color: $colorWhite;
            color: $colorWhite;
        }
    }

    &--whiteBlack{
        background: $colorWhite;
        border-color: $colorPrimaryBlack;
        color: $colorPrimaryBlack;
        &:hover{
            background: $colorPrimaryBlack !important;
            border-color: $colorWhite;
            color: $colorWhite;
        }
    }

    &--snow{
        background: $colorSnow;
        border-color: $colorSnow;
        color: $colorPrimaryBlack;
        &:hover{
            background: $colorStone2;
            border-color: $colorStone2;
            color: $colorPrimaryBlack;
        }
    }

    &--red{
        background: $colorRed;
        border-color: $colorRed;
        color: $colorWhite;
        &:hover{
            background: $colorBaseSale;
            border-color: $colorBaseSale;
            color: $colorWhite;
        }
    }

    &--dawnPink{
        background: $colorOffWhite;
        border-color: $colorOffWhite;
        color: $colorText;
        margin-right: 10px;
        &:hover{
            background: $colorPrimaryBlack;
            border-color: $colorPrimaryBlack;
            color: $colorWhite;
        }
    }
    &--transparent{
        padding: 0;
        height: auto;
        background: transparent !important;
        border-color: transparent !important;
        color: $colorPrimaryBlack;
        text-transform: uppercase;

    }
    &--outlined{
        background: transparent;
    }

    &--succes{
        background: $colorValid;
        border-color: $colorValid;
        color: $colorPrimaryBlack;

        &:after{
            content: "\e925";
            display: block;
            margin-left: $iconSpaceing;
            font-family: $fontFamilyIcon;
        }
        &:hover{
            background: $colorValid;
            border-color: $colorValid !important;
        }
    }

    &--ghost{
        border-color: $ButtonDisabled;
        color: $ButtonDisabled !important;
        background: $colorWhite;
        &:hover{
            background: $colorWhite !important; 
            border-color: $ButtonDisabled !important;
        }
    }
}


//icons

//Icons with a icon inside the button

.Button--iconFirst{
    [class*="icon"] {
        margin-right: $iconSpaceing;
    }
}
.Button--iconLast{
    [class*="icon"] {
        margin-left: $iconSpaceing;
    }
}


//Buttons with content icons //add if you want

.Button--add{
    &:before{
        content: "\e910";
        display: block;
        margin-right: $iconSpaceing;
        font-family: $fontFamilyIcon;
    }
}

.Button--arrow{
    &:after{
        content: "\e911";
        transition: left .3s ease-in-out;
        display: block;
        left: $iconSpaceing;
        font-family: $fontFamilyIcon;
        position: relative;
    }
    &:hover{
        &:after{
        left: 16px;
        }
    }
}

.Button--back{
    &:before{
        content: "\e913";
        transition: right .3s ease-in-out;
        display: block;
        right: $iconSpaceing;
        font-family: $fontFamilyIcon;
        position: relative;
    }
    &:hover{
        &:after{
        right: 16px;
        }
    }
}

.Button--round{
    font-family: $fontFamilyIcon;
    aspect-ratio: 1;
    padding: 0;
    height: 40px;
    width: 40px;
    &:before{
        margin: 0;
    }
    &.Button--plus{
        &:before{
            content: "\e910";
        }
    }
    &.Button--small{
        height: 24px;
        width: 24px;
    }

    &.Button--large{
        height: 56px;
        width: 56px;
        min-width: 0;
    }

}

.Button-roundText{
    margin-left: 15px;
}

.Button--title{
    position: relative;
    &:after{
      min-height: 26px;
      display: flex;
      align-items: center;
    }
  }