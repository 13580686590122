.PresentationMode-page {
 
    .add-users {
        position: fixed;
        bottom: 20px;
        left: 50%;
        z-index: 100;
        cursor: pointer;
    }
}
