.updateEntity {
    .addUser-popup {
        &--body {
            padding: 40px 27px 0;
            min-height: calc(100vh - 146px);
            max-height: calc(100vh - 146px);
            span {
                margin-top: 15px;
            }
        }
    }
    select {
        -webkit-appearance: auto !important;
        appearance: auto !important;
    }
    .tool {
        margin-left: 0px !important;
        float: right;
        top: 37px;
        left: 21px;
        &:after {
            margin-left: -22.7em !important;
        } 
    }
    select[name=trademarkName], input[name=shopId], input[name=supplierId]{
        margin-bottom: 0 !important;
    }
    input[name=partnerName] {
     margin-bottom: 0px !important;
    }
    input[name=sapId], input[name=supplierId]  {
        // margin-bottom: 25px !important;
    }
    .trademarkMultiDropdown {
        margin: 0px !important;
        .css-1s2u09g-control, .css-1pahdxg-control, .css-1insrsq-control {
            width: 100%
        }
        span {
            margin: 0px !important
        }
    }
}