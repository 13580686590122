@import "../../styles/variables.scss";

.hmusers-page {
    background-color: $colorOffWhite;
    .add-users {
        position: fixed;
        bottom: 20px;
        left: 50%;
        z-index: 100;
        cursor: pointer;
    }
    .userActive {
        cursor: pointer;
        background: #d9d7d4;
        &:last-child td:first-child {
            border-bottom-left-radius: 8px;
            border-top-left-radius: 8px;
        }
        td .Button {
            opacity: 1;
        }
    }
}
