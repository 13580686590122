@import "../../../styles/variables.scss";

.Hero-gradient {
  background: $colorOffWhite;
  overflow: hidden;
}


.Home {
  .Hero-gradient,
  .Dashbord-hero,
  .Page-gradient {
    background: $colorOffWhite;
  }

  .Dashboard-settings-icon,  .Button--transparent{
    &:hover {
      color: $colorHome2;
    }
  }

  .Button--transparent{
    &:hover {
      color: $colorHome1;
    }
  }

.Page-background{
  background-color: $colorHome1;
}

.Breadcrumb-link, .filter-section a, .filter-section button{
  &:hover{
    color: $colorBlack;
  }
}

  ////
  .Article {
    p, 
    li {
      a {
        color: $colorBlack;
        text-decoration: underline;
        &:hover {
          color: $colorBlack;
        }
      }
    }
  }
.Article-docLink{
  color: $colorHome1;
  &:hover {
    color: $colorHome2;
  }
}
  ///

  .CategoryTeaser, .CategoryTeaserFullWidth  {
    &:hover {
      background: $colorHome1;
      border-color: $colorHome1;
    }
  }
} // End Home

///// - Beauty - //////////////////////////////////////////////////////////////

.Beauty {
  .Hero-gradient,
  .Dashbord-hero,
  .Page-gradient  {
    background: $colorOffWhite;
  }

  .Dashboard-settings-icon {
    &:hover {
      color: $colorBeauty2;
    }
  }

  .Button--transparent{
    &:hover {
      color: $colorBeauty1;
    }
  }

  .Dashboard-settings-icon {
    &:hover {
      color: $colorBeauty2;
    }
  }

  .Breadcrumb-link, .filter-section a, .filter-section button{
    &:hover{
      color: $colorBlack;
    }
  }

  ///
  .Article {
    p,
    li {
      a {
        color: $colorBlack;
        text-decoration: underline;
        &:hover {
          color: $colorBlack;
        }
      }
    }
  }

  .Article-docLink{
    color: $colorBeauty1;
    &:hover {
      color: $colorBeauty2;
    }
  }

  ///
  .CategoryTeaser, .CategoryTeaserFullWidth  {
    &:hover {
      background: $colorBeauty1;
      border-color: $colorBeauty1;
    }
  }
  //
} // End Beauty

///// - Fashion - //////////////////////////////////////////////////////////////

.Fashion {
  .Hero-gradient,
  .Dashbord-hero,
  .Page-gradient  {
    background: $colorOffWhite;
  }

  .Dashboard-settings-icon {
    &:hover {
      color: $colorFashion2;
    }
  }

  .Button--transparent{
    &:hover {
      color: $colorFashion1;
    }
  }

  .Breadcrumb-link, .filter-section a, .filter-section button {
    &:hover{
      color: $colorBlack;
    }
  }
///
  .Article {
    p,
    li {
      a {
        color: $colorBlack;
        text-decoration: underline;
        &:hover {
          color: $colorBlack;
        }
      }
    }
  }

  .Article-docLink{
    color: $colorFashion1;
    &:hover {
      color: $colorFashion2;
    }
  }

  ///
  .CategoryTeaser, .CategoryTeaserFullWidth  {
    &:hover {
      background: $colorFashion1;
      border-color: $colorFashion1;
    }
  }

  
} //end Fashion


.Kids {
  .Hero-gradient,
  .Dashbord-hero,
  .Page-gradient  {
    background: $colorOffWhite;
  }

  .Dashboard-settings-icon {
    &:hover {
      color: $colorFashion2;
    }
  }

  .Button--transparent{
    &:hover {
      color: $colorFashion1;
    }
  }

  .Breadcrumb-link, .filter-section a, .filter-section button {
    &:hover{
      color: $colorBlack;
    }
  }
///
  .Article {
    p,
    li {
      a {
        color: $colorBlack;
        text-decoration: underline;
        &:hover {
          color: $colorBlack;
        }
      }
    }
  }

  .Article-docLink{
    color: $colorFashion1;
    &:hover {
      color: $colorFashion2;
    }
  }

  ///
  .CategoryTeaser, .CategoryTeaserFullWidth  {
    &:hover {
      background: $colorFashion1;
      border-color: $colorFashion1;
    }
  }

  
} //end Fashion
