@import "../../styles/variables.scss";
.Insight{
    .Hero{
        h1{
            color: $colorPrimaryBlack;
        }
    }
    .nav-tabs .nav-link {
        color: $colorBlack;
        border-bottom: 1px solid $colorStone2;
        min-width: 130px;
        &.active {
            color: $colorPrimaryBlack;
            border-bottom: 1px solid $colorPrimaryBlack !important;
        }
    }
    #react-select-2-listbox, #react-select-3-listbox{
        z-index: 3;
    }
    .users-table {
        .dataTable{
            height: 500px;
            padding-top: 25px;
            overflow: auto;

            &::-webkit-scrollbar {
                height: 6px;              
                width: 6px;      
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
            background: $colorStone3; 
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
            background: $colorGray; 
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
            background: $colorDarkGray; 
            }
            .table{
                white-space: nowrap;
                border-collapse: separate;
                border-spacing: 0;
                thead tr {
                    th, td {
                        color: $colorBlack;
                        font-weight: 600;
                        font-size: $textSizeLarge;
                        text-transform: none;
                        position: sticky;
                        left: 0;
                        top: -25px;
                        background: white;
                        z-index: 4;
                        // max-width: 223px !important;
                        // overflow: hidden;
                        &:first-child {
                            position: sticky;
                            left: 0;
                            top: -25px;
                            z-index: 5;
                        }
                        &:nth-child(2) {
                            position: sticky;
                            left: 23px;
                            top: -25px;
                            z-index: 5;
                            min-width: 40px;
                        } 
                        &:nth-child(3) {
                            position: sticky;
                            left: 60px;
                            top: -25px;
                            z-index: 5;
                        }
                    }
                    .columnResizerHeader {
                        background-color: $colorWhite;
                        width: 1px;
                        min-width: 1px;
                        max-width: 1px;
                        padding: 0 0 0 0;
                        border-bottom: 1px solid #9b6566 !important;
                        border-right: 1px solid #dee2e6 !important;
                    }                      
                }
                td { 
                    border-bottom: 1px solid #dee2e6 !important;
                    // position: sticky;
                    left: 0;
                    background: white;
                    z-index: 1;
                    max-width: 160px !important;
                    overflow: hidden;
                    font-size: $textSizeLarge;
                    &:first-child {
                        position: sticky;
                        left: 0;
                        z-index: 2;
                    }
                    &:nth-child(2) {
                        position: sticky;
                        left: 23px;
                        z-index: 2;
                    } 
                    &:nth-child(3) {
                        position: sticky;
                        left: 60px;
                        z-index: 2;
                    }
                    &:not(.columnResizer):not(.columnResizerHeader):not(:first-child) {
                        padding-left: 10px !important;
                    }
                    .hiddenVisibility {
                        visibility: hidden;
                    }

                    &.overFlowScroll {
                        max-width: 150px;
                        overflow: auto;
                    }

                    &::-webkit-scrollbar {
                        height: 4px;              
                        width: 4px;      
                    }
                    
                    /* Track */
                    &::-webkit-scrollbar-track {
                    background: $colorStone3; 
                    }
                    
                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                    background: $colorGray; 
                    }
                    
                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb:hover {
                    background: $colorDarkGray; 
                    }
                }
                .columnResizer {
                    background-color: $colorWhite;
                    width: 3px;
                    min-width: 3px;
                    max-width: 3px;
                    padding: 0 0 0 0;
                    border: none;
                    // pointer-events: none;
                }
            }
        }
    }

    .has-search {
        .form-control{
            padding-left: 40px;
            border-radius: 50px;
            border: 1px solid $colorPrimaryBlack;
            color: $colorBlack;
            min-height: 51px !important;
        }
        .dateRangePicker{
            padding-top: 12px !important;
        }
    }
    
    .has-search .form-control-search {
        position: absolute;
        z-index: 2;
        display: block;
        width: 50px;
        height: 15px;
        line-height: 50px;
        text-align: center;
        pointer-events: none;
        color: $colorPrimaryBlack;
    }

    .customPadding {
        padding-left: 5px;
        padding-right: 0px;
    }

    .searchTextBox {
        width: 25%;
    }

    .customDaterangepicker {
        width: 26%;
    }

    .market {
        // width: 18%;
        z-index: 8;
    }

    .brand {
        width: 18%;
        z-index: 7;
    }

    .category {
        width: 18%;
        z-index: 7;
    }

    .export {
        margin-top: -14px;
        margin-right: -25px;
        margin-bottom: 10px;
        justify-content: end;
        #exportLevel {
            z-index: 12;
            padding: 0;
            margin-left: 126px;
            width: 18.666667%;
        }
        .exportButton {
            text-align: end;
            margin-top: -54px;
            button {
                border: 0;
                background: $colorWhite;
            }
        }
        i {
            cursor: pointer;
        }
        .text {
            font-style: normal;
            font-weight: 400;
            font-size: $textSizeLarge;
            line-height: 120%;
            color: $colorPrimaryBlack;
        }
    }

    .icon-Download {
        margin-right: 10px;
        vertical-align: middle;
    }

    .daterangepicker-control-section {
        max-width: 246px;
        margin: 30px auto;
        padding-top: 50px;
    }

    .daterangepicker .drp-selected{ display: none!important; }
    
}