@import "../../styles/variables.scss";
@import "../../components/Global/Text/index.scss";

.uploadCampaign {
    background-color: $colorOffWhite;
    
    .pageTitle {
        padding-top: 160px;
        padding-bottom: 30px;
    }
    .Hero-gradient {
        padding-top: 100px;
        padding-bottom: 100px;
        margin-bottom: 25px;
    }  
}

.UpLoad-hero{
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
}

.UpLoad-heroText{
    max-width: 60%;
    color: $colorWhite; 
    @extend .Text-sm;
}

.UpLoad-heroCta{
    .Button{
        margin-right: 25px;
    }
    .Link{
        color: $colorWhite; 
    }
}

