@import "./variables.scss";

html {
	box-sizing: border-box;
	font-size: 100%;
}
 
*, *::before, *::after {
	box-sizing: inherit; // https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
}

body {
    font-family: $fontFamilySecondary;
	font-size: $textSizeLarge;
	background: $colorPrimaryWhite;
	color: $colorText;
	-moz-osx-font-smoothing: grayscale; // better font rendering on Firefox on mac
	-webkit-font-smoothing: antialiased;
	//background: linear-gradient(rgb(228, 228, 228), rgb(255, 255, 255)) no-repeat;
}

a{
	cursor: pointer;
	text-decoration: none;
	color: $colorLink;
	&:hover{
		color: $colorLinkHover
	}
}

p, li{
	font-weight: 200;
}

img{
	max-width: 100%;
}


.Copy-rightText{
	position: absolute;
	left: 23px;
	bottom: 28px;
	font-size: $textSizeXSmall;
	letter-spacing: 0.03em;	
	text-transform: uppercase;
	color: $colorWhite;
}

.preferred-browserText{
	position: absolute;
	bottom: 0px;
	font-size: $textSizeLarge;
	letter-spacing: 0.03em;	
	color: $colorBlack;
}

select.form-control{
    appearance: none!important;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAFCAYAAAB8ZH1oAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABHSURBVHgBfY7BDQAgCAMxTMAKTOJm6mZOwgqsoBg1MUHu1Tb3aGLmCgAFYhqqaieiNEv+SSJS0VIgL8kCnsWRr+Rin/fvhwHGGRqR+Vm6iwAAAABJRU5ErkJggg==') 94% / 10px no-repeat;
    border-color: $colorPrimaryBlack;
    margin-bottom: 0;
}

.Popup-header{
	padding: 19px 27px;
	border-bottom: 0.5px solid $colorBlack;
	.Heading{
		margin-bottom: 0;
	}
}

.Popup-close{
	&:before{
		font-size: $textSizeSmall;
		font-weight: 600;
	}
	&:hover{
		color: $colorGray;
	}
}

@media print {
    .printHidden {
        display: none;
    }
	
	.printShow {
		display: block !important;
	}

	.printShowInline {
		display: inline-block !important;
	}

	.app-content {
		padding-left: 0px;
	}

	h1 {
		break-before: always;	
	}

	table, img, svg {
		break-inside: avoid;
		display: block;
	}

	.accordion-collapse {
		display: block!important;
	}

	.col-lg-5 {
		width: 50%;
	}

}