@import "../../../styles/variables.scss";
.Link{
	cursor: pointer;
	text-decoration: none;
	color: $colorText;
	&:hover{
		text-decoration: underline;
	}
}

.Link--arrow{
    display: inline-flex;
	&:after{
		content: "\e911";
		position: relative;
		display: inline-flex;
		transition: left .3s ease-in-out;
		font-family: $fontFamilyIcon;
		left: 5px;
	}
	&:hover{
		text-decoration: none;
		&:after{
		left: 10px;
		}
	}
}
