.loader-area {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.6);
    z-index: 2000;

    &--content {
        position: fixed;
        left: 50%;
        top: 50%;
        z-index: 200;
        width: 100px;
        height: 100px;
        margin-top: -50px;
        margin-left: -50px;
    }

}