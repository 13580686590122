@import '../../styles/variables.scss';
.InstructionList{
    .Heading{
        text-transform: uppercase;
        font-family: $fontFamilyPrimary;
        font-size: $textSizeXXXLarge;
    }
}
.InstructionList-list{
    padding: 0;
    .Heading{
        font-size: $textSizeLarge;
    }
}
.InstructionList-listItem{
    list-style: none;
    padding-bottom: 15px;
    margin-bottom: 25px;
    p{
        margin-bottom: 0;
        font-size: $textSizeLarge;
        font-family: $fontFamilySecondary;
        a{
            font-weight: 400;
            text-decoration: underline;
            color: $colorRed;
        }
    }
}

.InstructionList-cta{
    .Heading{
        margin-bottom: 30px;
        font-size: $textSizeLarge;
    }
    .Button{
        text-transform: uppercase;
    }
}