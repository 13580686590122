@import '../../styles/variables.scss';
.UploadFooter{
    // display: flex;
    background: $colorDawnPink;
    margin-left: -50px;
    margin-right: -50px;
    padding: 30px 30px;
    justify-content: space-between;
    align-items: center;
    max-width: 1320px;
    margin: 0 auto;
    border-radius: 0 0  24px 24px;
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.UploadFooter-area{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.no-border {
    border: 0;
}
.UploadFooter-selectArea {
    display: inline-block;
}

.UploadFooter-selectWrapper{
    background: transparent;
    display: inline-block;
    margin-right: 20px;
    width: 230px;
    label{
        display: inline-block;
    }
}

.UploadFooter-ctaArea{
    border-left: 1px dashed $colorPrimaryBlack;
    padding-left: 50px;
    float: right;
}

.UploadFooter-delete{
    cursor: pointer;
    background: transparent;
    i{
        margin-right: 8px;
    }
    margin-right: 30px;
    &:hover{
        color: $colorRed;
    }
    &:disabled{
        &:hover{
            color: rgba(16, 16, 16, 0.3);
        }
    }
}
