@import "../../styles/variables.scss";

.Hero-gradientImage{
    background-image: linear-gradient(60deg, rgba(216,166,140,1) 0%, rgba(216,166,140,1) 51%, rgba(233,228,227,0) 70%), url('../../assets/images/learnHero.png');
    background-repeat: no-repeat;
    //background-size: cover;
    background-position: right;
    .Hero-multiImageWrapper{
        display: none;
    }
}

.right-inner-addon {
    position: relative;
}
.right-inner-addon input {
    padding-right: 35px !important;
}
.right-inner-addon i {
    position: absolute;
    right: 0px;
    padding: 12px 12px;
    cursor: pointer;
}

.learnPage {
    .Preamble {
        font-size: $textSizeXXXLarge;
        font-family: $fontFamilySecondary;
    }
    #notificationBtn {
        display: block; 
        position: fixed;
        bottom: 20px;
        right: 30px;
        z-index: 99; 
        border: none; 
        outline: none; 
        background-color: $colorPrimaryRed; 
        color: white;
        padding: 10px; 
        border-radius: 50px; 
        font-size: $textSizeXLarge;
        &:hover {
            background-color: $colorBlack; 
        }
        img {
            height: 30px;
            width: 30px;
        }
    }
    .searchList {
        background-color: $colorOffWhite;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 20px;
        margin-top: 20px;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
        &:hover {
            background-color: $colorBlack; 
            color: $colorWhite;
        }
    }
}