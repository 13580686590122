@import "../../../styles/variables.scss";

.CategoryTeaser{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 42px 34px 38px;
    border: 1px solid transparent;
    border-radius: 24px;
    min-height: 260px;
    width: 332px;
    transition: .3s ease-in-out;
    background-color: $colorOffWhite;
    color: $colorBlack;

    &:hover{
        color: $colorWhite;
        background: $colorBlack !important;
        border-color: $colorBlack !important;
        .CategoryTeaser-statusTag{
            background-color: $colorWhite;
            color: $colorPrimaryBlack;
        }
    }    
}

.CategoryTeaserFullWidth{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 42px 34px 38px;
    border: 1px solid transparent;
    border-radius: 24px;
    min-height: 260px;
    width: 100%;
    transition: .3s ease-in-out;
    background-color: $colorOffWhite;
    color: $colorBlack;

    &:hover{
        color: $colorWhite;
        background: $colorBlack !important;
        border-color: $colorBlack !important;
        .CategoryTeaser-statusTag{
            background-color: $colorWhite;
            color: $colorPrimaryBlack;
        }
    }    
}

.CategoryTeaser-quantity{
    font-size: $textSizeLarge;
    font-weight: 400;
    margin-bottom: 16px;
    display: block;
    text-transform: uppercase;
}

.CategoryTeaser-heading{
    margin-bottom: 16px;
    font-size:  $textSizeLarge !important;
}

.CategoryTeaser-status{
    margin-top: 1rem;
    
}

.CategoryTeaser-statusTag{
    display: inline-flex;
    background: $colorPrimaryBlack;
    color: $colorWhite;
    border-radius: 24px;
    height: 30px;
    align-items: center;
    padding: 0 20px;
    font-size:  $textSizeLarge;
}

.CategoryTeaser-text {
    font-family: $fontFamilySecondary;
    font-size:  $textSizeLarge;
}



