@import "../../../styles/variables.scss";

.CtaWidget{
    padding: 40px 50px;
    border-radius: 24px;
    &--blue{
        background: $colorOffWhite;
    }
}
.CtaWidget-text{
    font-size: $textSizeLarge;
    font-family: $fontFamilySecondary;
}
.CtaWidget-button{
    display: flex;
    justify-content: flex-end;
    .Button{
        text-transform: uppercase;
    }
}