.notificationArticles {
    .addUser-popup--body {
        max-height: calc(120vh - 155px);
    }
    .addUser-popup--body--notification {
        margin-top: 0%;
    }
    .row>*  {
        margin-top: 0%;
    }
}