@import "../../../styles/variables.scss";


@font-face {
    font-family: 'hm-icon';
    src:  url('fonts/hm-icon.eot?qo0xo3');
    src:  url('fonts/hm-icon.eot?qo0xo3#iefix') format('embedded-opentype'),
      url('fonts/hm-icon.ttf?qo0xo3') format('truetype'),
      url('fonts/hm-icon.woff?qo0xo3') format('woff'),
      url('fonts/hm-icon.svg?qo0xo3#hm-icon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'hm-icon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
$icon-Blocked: "\e928";
$icon-Calendar: "\e929";
$icon-ControlPlay: "\e92a";
$icon-Delete-circle: "\e92b";
$icon-delete-square: "\e92c";
$icon-dropdown: "\e92d";
$icon-File-text: "\e92e";
$icon-Folder: "\e92f";
$icon-PlusCircle: "\e930";
$icon-Basket: "\e914";
$icon-Clock: "\e915";
$icon-Cloud-upload: "\e916";
$icon-Coins: "\e917";
$icon-Content-file: "\e918";
$icon-Delete-bin: "\e919";
$icon-Download: "\e91a";
$icon-edit-view: "\e91d";
$icon-Envelope: "\e91e";
$icon-Geometric-square: "\e91f";
$icon-Icons-Circle-plus: "\e920";
$icon-Money-Graph: "\e921";
$icon-Phone: "\e922";
$icon-Remove: "\e923";
$icon-Return: "\e924";
$icon-Validation-Check: "\e925";
$icon-Validation-check-circle: "\e926";
$icon-Validation-check: "\e927";
$icon-Arrow-Left-L: "\e913";
$icon-Arrows-Down: "\e901";
$icon-Arrows-Left: "\e902";
$icon-Arrows-Right: "\e903";
$icon-Arrows-Up: "\e904";
$icon-Setting-Circle: "\e905";
$icon-Settings: "\e906";
$icon-User-multiple: "\e907";
$icon-User: "\e908";
$icon-dashboard: "\e909";
$icon-Exit: "\e90a";
$icon-Graph: "\e90b";
$icon-Info: "\e90c";
$icon-Learn: "\e90d";
$icon-Search: "\e90e";
$icon-Upload: "\e90f";
$icon-edit-write: "\e900";
$icon-Plus: "\e910";
$icon-Arrow-black-right: "\e911";
$icon-Close: "\e912";
$icon-Radiobutton-Active: "\e91b";
$icon-Radiobutton: "\e91c";


  //

  .icon-Blocked {
    &:before {
      content: $icon-Blocked; 
    }
  }
  .icon-Calendar {
    &:before {
      content: $icon-Calendar; 
    }
  }
  .icon-ControlPlay {
    &:before {
      content: $icon-ControlPlay; 
    }
  }
  .icon-Delete-circle {
    &:before {
      content: $icon-Delete-circle; 
    }
  }
  .icon-delete-square {
    &:before {
      content: $icon-delete-square; 
    }
  }
  .icon-dropdown {
    &:before {
      content: $icon-dropdown; 
    }
  }
  .icon-File-text {
    &:before {
      content: $icon-File-text; 
    }
  }
  .icon-Folder {
    &:before {
      content: $icon-Folder; 
    }
  }
  .icon-PlusCircle {
    &:before {
      content: $icon-PlusCircle; 
    }
  }
  .icon-Basket {
    &:before {
      content: $icon-Basket; 
    }
  }
  .icon-Clock {
    &:before {
      content: $icon-Clock; 
    }
  }
  .icon-Cloud-upload {
    &:before {
      content: $icon-Cloud-upload; 
    }
  }
  .icon-Coins {
    &:before {
      content: $icon-Coins; 
    }
  }
  .icon-Content-file {
    &:before {
      content: $icon-Content-file; 
    }
  }
  .icon-Delete-bin {
    &:before {
      content: $icon-Delete-bin; 
    }
  }
  .icon-Download {
    &:before {
      content: $icon-Download; 
    }
  }
  .icon-edit-view {
    &:before {
      content: $icon-edit-view; 
    }
  }
  .icon-Envelope {
    &:before {
      content: $icon-Envelope; 
    }
  }
  .icon-Geometric-square {
    &:before {
      content: $icon-Geometric-square; 
    }
  }
  .icon-Icons-Circle-plus {
    &:before {
      content: $icon-Icons-Circle-plus; 
    }
  }
  .icon-Money-Graph {
    &:before {
      content: $icon-Money-Graph; 
    }
  }
  .icon-Phone {
    &:before {
      content: $icon-Phone; 
    }
  }
  .icon-Remove {
    &:before {
      content: $icon-Remove; 
    }
  }
  .icon-Return {
    &:before {
      content: $icon-Return; 
    }
  }
  .icon-Validation-Check {
    &:before {
      content: $icon-Validation-Check; 
    }
  }
  .icon-Validation-check-circle {
    &:before {
      content: $icon-Validation-check-circle; 
    }
  }
  .icon-Validation-check {
    &:before {
      content: $icon-Validation-check; 
    }
  }

  .icon-Arrow-Left-L {
    &:before {
      content: $icon-Arrow-Left-L; 
    }
  }
  .icon-Arrows-Down {
    &:before {
      content: $icon-Arrows-Down; 
    }
  }
  .icon-Arrows-Left {
    &:before {
      content: $icon-Arrows-Left; 
    }
  }
  .icon-Arrows-Right {
    &:before {
      content: $icon-Arrows-Right; 
    }
  }
  .icon-Arrows-Up {
    &:before {
      content: $icon-Arrows-Up; 
    }
  }
  .icon-Setting-Circle {
    &:before {
      content: $icon-Setting-Circle; 
    }
  }
  .icon-Settings {
    &:before {
      content: $icon-Settings; 
    }
  }
  .icon-User-multiple {
    &:before {
      content: $icon-User-multiple; 
    }
  }
  .icon-User {
    &:before {
      content: $icon-User; 
    }
  }
  .icon-dashboard {
    &:before {
      content: $icon-dashboard; 
    }
  }
  .icon-Exit {
    &:before {
      content: $icon-Exit; 
    }
  }
  .icon-Graph {
    &:before {
      content: $icon-Graph; 
    }
  }
  .icon-Info {
    &:before {
      content: $icon-Info; 
    }
  }
  .icon-Learn {
    &:before {
      content: $icon-Learn; 
    }
  }
  .icon-Search {
    &:before {
      content: $icon-Search; 
    }
  }
  .icon-Upload {
    &:before {
      content: $icon-Upload; 
    }
  }
  .icon-edit-write {
    &:before {
      content: $icon-edit-write; 
    }
  }
  .icon-Plus {
    &:before {
      content: $icon-Plus; 
    }
  }
  .icon-Arrow-black-right {
    &:before {
      content: $icon-Arrow-black-right; 
    }
  }
  .icon-Close {
    &:before {
      content: $icon-Close; 
    }
  }
  .icon-Radiobutton-Active {
    &:before {
      content: $icon-Radiobutton-Active; 
    }
  }
  .icon-Radiobutton {
    &:before {
      content: $icon-Radiobutton; 
    }
  }
  


 
  ///

  .Icon--round{
    border-radius: 100%;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
     color: $colorWhite; 
     font-size: 12px;
     font-weight: 600;
    }
    &.Icon--green{
      background-color: $colorValid;
    }

    &.Icon--red{
      background-color: $colorRed;
    }

    &.Icon--black{
      background-color: $colorPrimaryBlack;
    }
  }

