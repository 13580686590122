@import "../../../styles/variables.scss";

.Checkbox {
  position: relative;
  display: flex;
  font-style: normal;

  .Checkbox-input:disabled + input[type="hidden"] + .Checkbox-label,
  .Checkbox-input:disabled + .Checkbox-label,
  &.is-disabled .Checkbox-label {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.Checkbox-input {
  opacity: 0;
  position: absolute;
  // checked styling for box
  &:checked + input[type="hidden"] + .Checkbox-label:before,
  &:checked + .Checkbox-label:before {
  }
  // checked styling for checkmark
  &:checked + input[type="hidden"] + .Checkbox-label:after,
  &:checked + .Checkbox-label:after {
    transform: scale(1);
    opacity: 1;
  }
  // focus styling for box
  &:focus + input[type="hidden"] + .Checkbox-label:before,
  &:focus + .Checkbox-label:before {
    /*box-shadow: 0 0 1px 1px @colorFocus;*/
  }

  &.input-validation-error ~ .Checkbox-label {
    &:before {
      background-color: $colorRed;
    }
  }
}

.Checkbox {
  &.Checkbox--default {
    .Checkbox-input {
        opacity: 0;
        position: absolute;
       
        // checked styling for checkmark
        &:checked + input[type="hidden"] + .Checkbox-label:after,
        &:checked + .Checkbox-label:after {
          transform: scale(0.8);
          opacity: 1;
        }
      }

    .Checkbox-label {
      padding-left: 20px;
      position: relative;
      cursor: pointer;
      &:before {
        content: "\e91f";
        position: absolute;
        left: 0;
        //top: 2px;
        display: block;
        background: transparent;
        font-family: $fontFamilyIcon;
      }

      &:after {
        content: "\e925";
        position: absolute;
        left: 1px;
        top: 2px;
        display: inline-block;
        z-index: 1;
        font-family: $fontFamilyIcon;
        transform: scale(0.8);
        opacity: 0;
        font-size: $textSizeLarge;
        font-weight: 600;
      }
    }
  }
}
///////


.Checkbox {
  &.Checkbox--round {
    .Checkbox-input {
        opacity: 0;
        position: absolute;
       
        // checked styling for checkmark
        &:checked + input[type="hidden"] + .Checkbox-label:after,
        &:checked + .Checkbox-label:after {
          transform: scale(1);
          opacity: 1;
        }
      }

    .Checkbox-label {
      padding-left: 20px;
      position: relative;
      cursor: pointer;
      width: 100%;
      &:before {
        content: "\e91c";
        position: absolute;
        left: 0;
        //top: 2px;
        display: block;
        background: transparent;
        font-family: $fontFamilyIcon;
       
      }

      &:after {
        content: "\e926";
        position: absolute;
        left: 0px;
        display: inline-block;
        z-index: 1;
        font-family: $fontFamilyIcon;
        transform: scale(1);
        opacity: 0;
        font-weight: 600;
    
      }
    }
  }
}
///////

//////
.Checkbox {
  &.Checkbox--transparent {
    .Checkbox-input {
        opacity: 0;
        position: absolute;
        // checked styling for checkmark
        &:checked + input[type="hidden"] + .Checkbox-label:after,
        &:checked + .Checkbox-label:after {
          transform: scale(1.15);
          opacity: 1;
        }
      }
    .Checkbox-label {
      padding-left: 25px;
      position: relative;
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        display: block;
        background: transparent;
        background-image: url('../../../assets/images/icons/Checkbox-transparent.svg');
        background-repeat: no-repeat;
      }

      &:after {
        content: "";
        position: absolute;
        left: 6px;
        top: 6px;
        width: 20px;
        height: 20px;
        display: inline-block;
        z-index: 1;
        transform: scale(0.8);
        opacity: 0;
        font-size: $textSizeLarge;
        background-image: url('../../../assets/images/icons/Checkbox-transparent-checked.svg');
        background-repeat: no-repeat;
      }
    }
  }
}
