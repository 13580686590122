@import "../../styles/variables.scss";
.customDatepickerInput {
    text-transform: capitalize;
    background-color: $colorWhite;
    border-style: solid;
    border-color: #222 ;
    border-radius: 40px;
    min-height: 51px;
    border-width: 1px;
    cursor: default;
    outline: 0;
    transition: all 100ms;
    box-sizing: border-box;
    font-family: $fontFamilySecondary;
    font-size: $textSizeLarge;
    font-style: normal;
    font-weight: 400;
    width: 100%;
}
.dataTableContainer {
    .react-datepicker-popper {
        z-index: 8;
        .react-datepicker__day.react-datepicker__day--keyboard-selected {
            background: none;
            color: black;
        }
    }
}
