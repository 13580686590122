@import '../../styles/variables.scss';

.ProfileWidget {
  text-align: center;
  .dropdown {
    position: relative;
    border: 1px solid $colorStone3;
    border-radius: 6px;
    #dropdown-basic {
      width: 100%;
      text-align: left;
      font-size: $textSizeLarge;
      &:after {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        border-top-color: $colorGray;
      }

      &:focus {
        box-shadow: none;
      }
    }
    .dropdown-menu {
      width: 100%;
      font-size: $textSizeLarge;
    }
  }
  .portfolioBrand {
    text-align: left;
  }
}

.ProfileWidget-content {
  //  display: flex; uncomment when settings comes to the site
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ProfileWidget-userImage {
  width: 100%;
  text-align: center;
  display: none;
  img {
    border-radius: 100%;
    max-height: 40px;
    max-width: 40px;
    margin-right: 6px;
  }
}

.ProfileWidget-user {
  display: flex;
  flex-direction: column;
}

.ProfileWidget-userName {
  display: block;
  font-size: $textSizeLarge;
  font-family: $fontFamilyPrimary;
  text-transform: uppercase;
}

.ProfileWidget-userTitle {
  font-size: $textSizeLarge;
  color: $colorTextSecondery;
}

//

.Navigation {
  .ProfileWidget-button {
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    aspect-ratio: 1;
    [class*='icon'] {
      margin: 0;
    }
  }

  &--extended {
    .ProfileWidget-button {
      width: 100%;
      aspect-ratio: unset;
      height: 44px;
      border-radius: 37px;
      [class*='icon'] {
        margin-right: 15px;
      }
    }
    .ProfileWidget {
      // text-align: left;
    }
  }

  &:not(.Navigation--extended) {
    .ProfileWidget {
      .dropdown {
        #dropdown-basic {
          white-space: normal;
          font-size: $textSizeLarge;
        }
        .dropdown-menu {
          font-size: $textSizeLarge;
          .dropdown-item {
            padding: 5px;
          }
        }
      }
    }
  }
}
