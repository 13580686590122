@import "../../../styles/variables.scss";
.Title {
  &:hover {
    // position: relative;
    &:after {
      content: attr(title);
      position: absolute;
      top: -100%;
      left: 0;
      background: $colorBlack;
      border-radius: 6px;
      padding: 5px 8px;
      font-size: $textSizeXSmall;
      text-align: center;
    }
    &:before {
      content: "";

      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid $colorBlack;
      position: absolute;
      left: 0%;
      right: 0;
      top: -100%;
    }
    &--large {
      &:after {
        width: 100%;
      }
    }
  }
}

[data-title]:hover:after,
[data-title]:hover:before {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: $colorBlack;
  color: $colorWhite;
  font-size: $textSizeLarge;
  position: absolute;
  padding: 4px 10px;
  // top: -170%;
  top: -40px;
  left: auto;
  white-space: nowrap;
  border-radius: 6px;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  text-transform: capitalize;
  font-family: $fontFamilySecondary;
  text-transform: none;
}

[data-title]:before {
  content: "";
  opacity: 0;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid $colorBlack;
  position: absolute;
  left: 20%;
  right: -50%;
  // top: -50%;
  top: -14px;
  margin-right: 0px;
  visibility: hidden;
}

.Navigation {
  .Navigation-listItem {
    [data-title]:after {
      content: attr(data-title);
      background-color: transparent;
      // background-image: url('../../../assets/images/icons/Navigation-title.svg');
      background: $colorWhite;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      color: $colorText;
      
    }

    [data-title]:before {
      content: "";
      border-top: 0px solid $colorGray;
    }
  }
}

// .Navigation {
//   .Navigation-listItem {
//     .Title {
//       &:hover {
//         // position: relative;
//         &:after {
//           top: -25px;
//           left: 0;
//           right: 0;
//           background: $colorWhite;
//           font-size: $textSizeXSmall;
//           box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
//         }
//         &:before {
//           border-top: 0px solid $colorGray;
//         }
//       }
//     }
//   }
// }
