@import "../../../styles/variables.scss";
.Clock{
    color: $colorWhite;
    position: absolute;
    right: 25px;
    bottom: 28px;

    .react-clock__mark__body {
        background-color: $colorWhite !important;
    }

    .react-clock__hour-hand__body, .react-clock__minute-hand__body{
        background-color: $colorWhite;
    }
    .react-clock__second-hand__body{
        background-color: $colorDarkGray;
    }
}
.Clock-content{
    display: flex;
    align-items: center;
}

.Clock-graphic{
    img{
    animation: turn 200s  linear infinite alternate;
}
}

.Clock-list{
    list-style: none;
    margin: 0;
    padding-left: 15px;
}

.Clock-listItem{
    text-transform: uppercase;
    font-size: $textSizeLarge;
    line-height: 1.3;
    
    span{
        display: inline-block;
        min-width: 50px;
        font-weight: 400;
    }
    .Clock-timeType{
        margin-left: 15px;
        min-width: none;
        position: relative;
    }
    &--current{
        .Clock-timeType{
            &:after{
                content: "•";
                position: absolute;
                left: -14px;
                top: 0px;
                font-size: 18px;
            }
        }
    }
}



@keyframes turn {
    from {
        transform: rotateZ(0deg)
      }
      to {
        transform: rotateZ(360deg)
      }
  }