@import "../../../styles/variables.scss";
.container, .container-xl{
    //max-width: 1120px;
    padding: 0 30px;
    max-width: 1075px;
}

.Page-gradient{
    height: 40vh;
    background: linear-gradient(
        180deg,
        $colorHome1 43%,
        rgba(99, 120, 106, 0.78) 63%,
        rgba(255, 255, 255, 0) 100%
    );
} 

.Page-background{
    min-height: 100vh;
} 