@import "../../../styles/variables.scss";
.Breadcrumb{
    padding: 0;
}

.Breadcrumb-item{
    display: inline-block;
    list-style: none;
    font-size: $textSizeXSmall;
    color: $colorBlack;
    text-transform: uppercase;
}

.Breadcrumb-link{
    display: block;
    color: $colorDarkGray;
    &:after{
        content: "\e903";
        margin: 0 8px;
        font-family: $fontFamilyIcon;
        font-size: $textSizeXSmall;
        color: $colorDarkGray!important;
        display: inline-block;
        text-decoration: none;
    }
    &:hover{
        color: $colorBlack !important;
        text-decoration: underline;
    }
}
.Breadcrumb-current{
    font-weight: 400;
}