@import './styles/variables.scss';

//classes

.app-content {
    transition: .3s ease-in-out;
    padding-left: 250px;
    width: 100%;
    float: left;
}
.small-padding {
    transition: .3s ease-in-out;
    padding-left: 120px;
    .chatBox{
        padding: 24px 0px 0px 186px;
    }
}

.no-padding {
    transition: .3s ease-in-out;
    padding-left: 0px;
}

.pull-left {
    float: left;
}
.pull-right{
    float: right;
}
button:disabled {
    cursor: not-allowed;
}

.css-1s2u09g-control, .css-1pahdxg-control, .css-1insrsq-control {
    background-color: none !important;
    border-color: #222 !important;
    border-radius: 40px !important;
    min-height: 51px !important;
    padding: 0 12px 0 10px !important;
    font-family: $fontFamilySecondary;
    font-size: $textSizeLarge;
    font-style: normal;
    font-weight: 400;
    box-shadow: none !important;
}
.css-14el2xx-placeholder {
    color: rgba(0,0,0,.65) !important;
}
.css-1okebmr-indicatorSeparator {
    display: none !important;
}
.css-tlfecz-indicatorContainer {
    color: #222222 !important;
}
.css-b62m3t-container {
    font-family: $fontFamilySecondary !important;
    margin-bottom: 10px;
}

.hide-access {
    visibility: hidden;
}

.daterangepicker {
    font-family: $fontFamilyPrimary;
    font-style: normal;
    font-weight: 400;
    font-size: $textSizeLarge;
    line-height: 17px;
    th {
        font-size: $textSizeLarge!important;
    }
    td {
        &.available{
            border-radius: 50px;
            &:hover {
                background-color: #F1EBDF;
                border-color: transparent;
                color: inherit;
            }
        }
        &.in-range {
            background-color: #F1EBDF;
            border-color: transparent;
            color: #000;
            border-radius: 0;
        }
        &.active {
            background-color: #222222;
            border-color: transparent;
            color: #fff;
            &:hover {
                background-color: #222222;
                border-color: transparent;
                color: #fff;
            }
        }
        &.start-date {
            border-radius: 50px!important;
        }
        &.end-date {
            border-radius: 50px;
        }
    }
    [data-title]:hover:after, [data-title]:hover:before {
        display: none;
    }
    .month {
        font-size: $textSizeLarge!important;  
    }
    .drp-calendar.right {
        display: none;
    }
    .drp-selected { 
        display: none!important; 
    }
    .drp-buttons .applyBtn {
        background-color: #222222;
        border-color: #222222;
        border-radius: 20px;
        width: 100px;
    }
}

.page-item {
    &.active .page-link{
        background: #222222;
        border-radius: 16px;
    }
    
    .page-link {
        border: solid 1px #ffff !important;
        color: #222;
    }
}

.no-border {
    border: 0;
}

.UploadFooter-delete{
    cursor: pointer;
    background: transparent;
    i{
        margin-right: 8px;
    }
    margin-right: 30px;
    &:hover{
        color: $colorRed;
    }
    &:disabled{
        &:hover{
            color: rgba(16, 16, 16, 0.3);
        }
    }
}

button {
    text-transform: uppercase;
    font-family: $fontFamilyPrimary;
}

strong {
    font-weight: bold;
}

.form-control {
    border: 1px solid $colorBlack;
    // padding-left: 30px !important;
}

.fontFamilySecondary {
    font-family: $fontFamilySecondary !important;
    text-transform: none;
}

