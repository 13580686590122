@import '../../styles/variables.scss';

.partnerInformation {
    .Breadcrumb-item {
        color: $colorBlack;
    }
   
    .users-table {
        margin-bottom: 10px;
        padding: 30px 20px 40px !important;
        table {
            thead { 
                border-bottom: 0.5px solid $colorBlack;
                tr {
                    th {
                        color: $colorBlack;
                        font-size: $textSizeLarge;
                        font-weight: 600;
                        text-transform: capitalize;
                    }
                }
            }
            tr {
                &:hover{
                    td {
                        color: $colorBlack;
                    }
                }
                td {
                    color: $colorBlack;
                    font-size: $textSizeLarge;
                }
            }
        }
    }
}

.accordion {
    margin-top: 20px;

    .users-table {
        margin-top: 0;
        padding: 0px 20px 40px 20px;
    }

    .add-entity {
        border-top: 0.5px solid #D0D0D0;
        padding: 10px 0;
        text-align: center;

        span {
            font-family: $fontFamilyPrimary;
            font-style: normal;
            font-weight: normal;
            font-size: $textSizeLarge;
            line-height: 120%;            
            letter-spacing: -0.01em;
            color: #97999B;
        }
    }

    .accordion-item:last-of-type .accordion-button.collapsed {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    .accordion-item {
        background: #FFFFFF;
        border: 0;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        margin-bottom: 10px;
    }

    .accordion-button {
        border-radius: 8px;
        font-family: $fontFamilyPrimary;
        font-style: normal;
        font-weight: normal;
        font-size: $textSizeLarge;
        line-height: 140%;
        letter-spacing: 0.015em;
        color: $colorBlack;

        span.entities {
            font-family: $fontFamilyPrimary;
            font-style: normal;
            font-weight: normal;
            font-size: $textSizeLarge;
            line-height: 160%;
            letter-spacing: 0.02em;
            color: #53565A;
            position: absolute;
            right: 50px;
        }

        .collapsed {
            border-radius: 8px;
        }

        img {
            border-radius: 50%;
            margin-right: 14px;
        }

        &:not(.collapsed) {
            background-color: #ffffff;
            box-shadow: none;
            border-radius: 8px 8px 0 0;

            &::after {
                background: none;
            }
        }
        &::after {
            width: 14px;
            height: 14px;
            background: none;
        }
    }

    .accordion-body {
        padding: 0;
    }
}

