@import "../../../styles/variables.scss";
//Margins
.u-marginTlg{
    margin-top: 2rem;
}

.u-marginBlg{
    margin-bottom: 2rem;
}
.u-marginBxlg{
    margin-bottom: 100px;
}
//Paddings

.u-paddingAlg{
    padding: 2rem;
}

// Position
.u-positionFixed{
    position: fixed;
}

//backgrounds
.u-backgroundWhite{
    background: $colorWhite;
}

.u-backgroundBlue{
    background: $colorBlue;
}
.u-backgroundPink{
    background: $colorPink;
}
.u-backgroundDawnPink{
    background: $colorDawnPink;
}

.u-backgroundStone1{
    background: $colorStone1;
}

.u-backgroundStone2{
    background: $colorStone2;
}

// .u-backgroundStone3{
//     // background: $colorStone3;
    
// }

//TextColors

.u-textWhite{
    color: $colorWhite;
}

.u-textBlack{
    color: $colorBlack;
}

.u-textBlue{
    color: $colorBlue;
}


// Font-sizes
.u-textSizelg{
    font-size: $textSizeLarge;
}

.u-textSizeXXl{
    font-size: $textSizeXXLarge;
}

.u-textSizeXXXl{
    font-size: $textSizeXXXLarge;
}

.u-textLight{
    font-weight: 200;
}

//font-style

.u-textSerif{
    font-family: $fontFamilySecondary;
}

//alignments
.u-textCenter{
    text-align: center;
}

.u-textRight{
    text-align: right;
}

.u-center{
    margin-left: auto;
    margin-right: auto;
}

//styleguide
.u-spaceOnNext > *, .u-spaceOnNext + *{
    margin: 5px;
}

//Display

.u-flex{
    display: flex;
}

//Position

.u-posRelative{
    position: relative;
}

//overflow

.u-overflowX-hidden{
    // overflow-x: hidden;
}

.u-bold {
    font-weight: 600;
}

.u-textNormal {
    font-weight: 400;
}