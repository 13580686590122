@import '../../styles/variables.scss';

.Navigation {
  transition: 0.3s ease-in-out;
  background: $colorWhite;
  width: 120px;
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  &--extended {
    width: 250px;
  }
}
.Navigation-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  border-bottom: 1px solid $colorStone3;
  padding-bottom: 30px;
  &.NavigationList-secondary {
    padding: 0;
    border: none;
  }
}
.Navigation-listItem {
  cursor: pointer;
  position: relative;
  &.hasSubmenu {
    font-family: $fontFamilySecondary;
    text-transform: capitalize;
    padding-left: 5px;
    [data-title]:after {
      content: attr(data-title);
      opacity: 1 !important;
      top: -30px;
    }
  }
  &:before {
    content: "";
    border-left: 2px solid $colorWhite;
    display: block;
    position: absolute;
    left: -25px;
    width: 2px;
    height: 25px;
    top: 50%;
    transform: translateY(-50%);
  }
  .Navigation-notifyer,
  .Navigation-active {
    display: none;
  }
  .is-active {
    color: $colorPrimaryBlack;
    .Navigation-active {
      display: block;
    }
  }
}

.Navigation-listLink {
  text-transform: uppercase;
  font-family: $fontFamilyPrimary;
  font-size: $textSizeLarge;
  color: $colorGray;
  display: flex;
  padding: 18px 20px;
  border-radius: 18px;
  background: $colorWhite;
  justify-content: center;
  align-items: center;
  &.hasSubmenu {
    font-family: $fontFamilySecondary;
    text-transform: none;
    padding: 2px 0px;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  i {
  }
  span {
    transition: 0.3s ease-in-out;
    max-width: 0;
    opacity: 0;
    line-height: 0;
  }
  &:hover {
    background: $colorSnow;
    color: $colorPrimaryBlack;
  }
}

.Navigation-toggleText {
  transition: 0.3s ease-in-out;
  max-width: 0;
  opacity: 0;
  line-height: 0;
}

// Logo
.Navigation-logoWrapper {
  text-align: -webkit-center;
  //    margin-bottom: 100px;
  margin-bottom: 50px;
}

.Navigation-logo {
  max-width: 50px;
}

.Navigation-logo-customWidth {
  max-width: 100% !important;
}

.Navigation-logo--Large {
  display: none;
}
.Navigation-logo--small {
  display: block;
  margin: 0 auto;
}
//
.Navigation-notifyer {
  display: block;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  background: $colorRed;
  position: absolute;
  top: 16px;
  left: 40px;
}
.Navigation-active {
  display: block;
  width: 2px;
  height: 32px;
  border-right: 2px solid #e50010;
  position: absolute;
  top: 10px;
  left: 0px;
  margin-left: -25px !important;
  opacity: 1 !important;
}
//
.Navigation-lowerContent {
  padding-top: 40px;
}

//
.Navigation-helpLink {
  color: $colorGray;
  i {
    margin-right: 10px;
  }
}

.Navigation-SlideTrigger {
  // transition: box-shadow .3s ease-in-out;
  transition: 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  aspect-ratio: 1;
  background: $colorWhite;
  position: fixed;
  left: 100px;
  cursor: pointer;
  box-shadow: 0px 0px 10.6695px rgb(0 0 0 / 10%);
  z-index: 100;
  &::before {
    content: "\e903";
    font-family: $fontFamilyIcon;
  }
  &:hover {
    box-shadow: 0px 0px 10.6695px rgba(0, 0, 0, 0.4);
  }
}

//Extended
.Navigation {
  &--extended {
    .Navigation-listLink {
      justify-content: flex-start;

      span {
        opacity: 1;
        max-width: 100%;
        margin-left: 15px;
        line-height: 120%;
      }
    }
    .Navigation-logoWrapper {
      padding: 0 25px;
      text-align: -webkit-center;
      // margin-bottom: 120px;
    }
    .Navigation-logo {
      max-width: 67px;
    }

    .Navigation-notifyer {
      top: 14px;
      left: 35px;
    }

    .Navigation-toggleText {
      opacity: 1;
      max-width: 100%;
      line-height: 120%;
    }

    .ProfileWidget-userImage {
      width: unset;
      display: none; //removed until we can add userImage
    }

    .Navigation-SlideTrigger {
      left: 230px;
      &::before {
        transform: scaleX(-1);
      }
    }

    .Navigation-logo--Large {
      display: block;
    }
    .Navigation-logo--small {
      display: none;
    }
    // Title not showing when nav is expanded
    .Navigation-listItem {
        [data-title]:after {
            content: attr(data-title);
            opacity: 0;
          }
    }
  }
}

.Navigation-position {
  position: fixed;
  left: 0;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: lightgrey;
  }
  &::-webkit-scrollbar-thumb {
    background: darkgrey;
  }
}
