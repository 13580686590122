@import "../../../styles/variables.scss";
.Tabs-overflow{
    cursor: pointer;
    // margin-top: -150px;
    overflow-x: auto;

//SCROLL
/* width */
&::-webkit-scrollbar {
    height: 8px;

  }
  
  /* Track */
&::-webkit-scrollbar-track {
    background: $colorLightGrey;
    
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $colorBlack;
    border-radius: 5px;
   
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $colorBlack;
  }

    
}
.Tabs{
    display: flex;
    white-space: nowrap;
    .Tabs-heading {
        font-weight: normal;
    }
}

.Tabs-item{
    // white-space: normal;
    transition: border-color .3s ease-in-out;
    display: inline-block;
    list-style: none;
    // width: 300px;
    border-top: 3px solid transparent;
    padding: 0 24px;
    border-radius: 0 0 20px 20px;
    position: relative;
    // min-width: 300px;
   
    &:hover{
        border-color: $colorBlack;
    }
    &.is-active{
        background: $colorBlack;
        border-color: $colorBlack;
        color: $colorWhite;
        .Tabs-heading, .Tabs-text{
            color: $colorWhite;
            text-transform: uppercase;
        }
    }

    .downloadIcon{
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.Tabs-link{
    display: block;
    width: 100%;
    min-height: 96px;
    display: flex;
    align-items: center;
    .Tabs-heading, .Tabs-text{
        color: $colorText;
        text-transform: uppercase;
    }
}

///
.nav-tabs {
    border-bottom: 1px solid $colorLightGrey;
    .nav-link {
        font-family: $fontFamilyPrimary;
        font-style: normal;
        text-transform: uppercase;
        font-weight: initial;
        font-size: $textSizeXXXLarge;
        line-height: 33.26px;
        letter-spacing: 0.015em;
        color: $colorBlack;
        border: none;

        &:focus, &:hover {
            color: $colorWhite;
            background: $colorBlack;
        }

        &.active {
            color: $colorBlack;
            background: none;
            border-color: transparent;
            border-bottom: 1px solid $colorBlack !important;
            font-weight: 400;
        }
    }
}

