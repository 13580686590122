@import "../../../styles/variables.scss";

.Feedback{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px 15px 40px;
    width: 100%;   
    background: $colorWhite;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
    margin-bottom: 70px;

    .Button{
        margin-right: 25px;
    }
    label{
        margin-bottom: 0!important;
        text-transform: none;
    }
    p{
        margin-bottom: 0;
    }
}



.Feedback-chapter{
    img{
        margin-right: 25px;
    }
}

.Feedback-chapterLink{
    transition: 0.3s ease-in-out;
    display: flex;
    align-items: center;
    border-radius: 16px;
    padding: 10px 35px 10px 12px;
    &:hover{
        background: $colorDawnPink;
    }
}