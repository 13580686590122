@import '../../styles/variables.scss';
.exportDropdown {
  .dropdown {
    background-color: $colorWhite;
  }
  
  .dropdown-header {
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: end;
    align-items: center;
  }
  
  .dropdown-body {
    display: none;
  }
  
  .dropdown-body.open {
    display: block;
    position: absolute;
    width: -webkit-fill-available;
    background-color: $colorWhite;
    margin-top: 7px;
    border-radius: 4px;
    border: 1px solid $colorStone1;
    z-index: 20;
  }
  
  .dropdown-item {
    padding: 10px;
  }
  
  .dropdown-item:hover {
    cursor: pointer;
    background-color: #DEEBFF;
  }
  
  .dropdown-item.selected {
    background-color: #2684FF;
    color: $colorWhite;
  }
}