@import '../../../styles/variables.scss';

.users-table {
    background: $colorWhite;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 30px 20px 40px;
    margin-top: 20px;
    // margin-bottom: 100px;
    
    table {
        
        thead {
            border-bottom: 0.5px solid $colorBlack;
        }

        tbody {
            border: none !important;

            tr {
               

                &:first-child {
                    padding-top: 0.5 rem;
                }
            } 
        }

        tr {
            
            th {
                font-family: $fontFamilySecondary;
                font-style: normal;
                font-weight: 600;
                font-size: $textSizeLarge;
                line-height: 27.22px;
                /* or 14px */

                letter-spacing: 0.03em;

                /* Brand colors/Orchid */

                color: $colorBlack;
                padding-left: 0px;
            }
            td {
                border: none !important;
                font-family: $fontFamilySecondary;
                font-style: normal;
                font-weight: normal;
                font-size: $textSizeLarge;
                line-height: 21.6px;
                /* identical to box height, or 24px */

                letter-spacing: 0.015em;

                color: $colorBlack;
                padding: 0.8rem 0.5rem;
                padding-left: 0px;

                .Button{
                    opacity: 0.15;
                }

                &.ellipsisText {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 150px;
                }

            }
            &:hover{

                    
                &:first-child td:first-child { border-top-left-radius: 8px; border-bottom-left-radius: 8px; }
                &:first-child td:last-child { border-top-right-radius: 8px; border-bottom-right-radius: 8px;}
                &:last-child td:first-child { border-bottom-left-radius: 8px; border-top-left-radius: 8px;}
                &:last-child td:last-child { border-bottom-right-radius: 8px; border-top-right-radius: 8px;}


                td{
                    background: $colorSnow;
                    .Button{
                        opacity: 1;
                    }
                }
            }
        }

       
    }
}