@import '../../styles/variables.scss';
$card-height: 330px;
.ProductAdd{
    border: 1px solid $colorPrimaryBlack;
    border-radius: 20px;

    &.ProductAdd--large{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .Heading{
            max-width: 220px;
        }
    }
}

.ProductAdd-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 35px 20px 0px 20px;
    h6 {
        font-weight: 400;
    }

    .Button{
        margin-bottom: 20px;
        width: 20px;
        height: 20px;
        &::before {
            font-size: 10px;
        }
        &:hover {
            border-color: $colorBlack;
        }
        &:disabled {
            &:hover {
                color: $colorWhite;
            }
        }
    }
    .Heading{
        margin-bottom: 20px;
    }
}

.ProductAdd-directions{
    display: block;
    color: $colorTextHint;
}

.show-failed {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}