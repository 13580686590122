@import "../../../styles/variables.scss";

.Text-xsm{
    font-size: $textSizeXSmall;
}

.Text-sm{
    font-size: $textSizeSmall;
    font-size: 15px;
    line-height: 160%;
    letter-spacing: 0.01em;
}

.Text-md{
    font-size: $textSizeMedium;
}

.Text-lg{
    font-size: $textSizeLarge;
}

.Text-xlg{
    font-size: $textSizeXLarge;
}

.Text-xxlg{
    font-size: $textSizeXXLarge;
}
