@import '../../styles/variables.scss';

.notificationSideBar {
    .secondaryFont {
        font-family: $fontFamilySecondary;
        text-transform: none;
        margin-bottom: 20px;
    }
    .successIcon {
        height: 40px;
        width: 40px;
        margin-bottom: 20px;
    }
}