@import "../../../styles/variables.scss";

.Toc{
   text-align: center;
   margin-bottom: 110px;
   white-space: nowrap;
   overflow-x: auto;

   //SCROLL
/* width */
&::-webkit-scrollbar {
    height: 8px;

  }
  
  /* Track */
&::-webkit-scrollbar-track {
    background: $colorLightGrey;    
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $colorBlack;
    border-radius: 5px;
   
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $colorBlack;
  }
}

.Toc-list{
    list-style: none;
    margin: 0;
    padding: 15px 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin-bottom: 5px;
}

.Toc-listItem{
    display: inline-flex;
    padding-left: 4px;
    align-items: center;


    .Toc-listLink{
        font-weight: 500;
        font-size: $textSizeSmall;
      
       padding: 4px;
        &:hover{
            background-color: $colorOffWhite;
            border-radius: 8px;
            color: $colorBlack;
        }
    }
    &:after{
        content: "|";
        padding-left: 4px;
        color: $colorDarkGray;
    }
    &:last-child{
        &:after{
            content: "";
            padding-left:4px;
        }
    }
}

.Toc-Heading{
  //  margin-top: 4rem;
}