.onBoardingCompleted{
    // background-image: url('../../assets/images/bedRoom.png');
    // background-repeat: no-repeat;
    // background-size: 50%;

    .row{
        min-height: 100vh;
    }
    .Indent-hero{
        position: relative;
        z-index: 2;
    }
}

.onBoardingCompleted-image{
    background-image: url('../../assets/images/bedRoom.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 50%;
    height: 90%;
    position: absolute;
    z-index: 1;
}