@import "../../../styles/variables.scss";

.NextArticle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 25px 25px 40px;
    width: 100%;   
    background: $colorOffWhite;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
    margin-bottom: 70px;

    label{
        margin-bottom: 0!important;
        text-transform: none;
        font-weight: 600;
    }
    p{
        margin-bottom: 0;
    }
    &:hover{
        .Feedback-chapterLink{
            background: $colorWhite;
        }
    }
}



