@import '../../../../styles/variables.scss';
.Sizes{
    .Hero{
        padding: 50px 0;
        h1{
            color: $colorPrimaryBlack;
        }
    }
    .Button{
        .icon-Plus{
            padding-right: 8px;
        }
        &.red{ 
            background-color: $colorPrimaryRed; 
            color: $colorPrimaryWhite;
            &:hover{
                background-color: $colorPrimaryRed;
                color: $colorPrimaryWhite;
            }
        }
        &.green {  
            background-color: $colorPrimaryGreen; 
            color: $colorPrimaryWhite;
            &:hover{
                background-color: $colorPrimaryGreen;
                color: $colorPrimaryWhite;
            }
        }
    }
    .placeholderImage{
        margin-bottom: 20px;
        img {
            border-radius: 29.2px;
        }
    }
    .heading{
        font-size: 18px;
        line-height: 125%;
        letter-spacing: 0.01em;
        font-weight: 600;
        font-family: $fontFamilySecondary;
    }
}