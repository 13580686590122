@import "../../styles/variables.scss";
@import "../../components/Global/Text/index.scss";

.drop-ship-invoice {
    .printShow {
        display: none;
    }
    .printShowInline {
        display: none;
    }
    .invoiceDate {
        border: none;
        padding-left: 0px;
        border-bottom: 1px solid $colorLightGrey;
        border-radius: 0px;
        outline: none !important;
        color: $colorBlack;
        &:focus {
            outline: none !important; 
            box-shadow: 0 0 2px 2px $colorWhite;
        }
    }
    input {
        color: $colorBlack;
        &::placeholder {
            color: $colorLightGrey;
        }
    }
    .totalAmount {
        border-top: 0.5px solid #F0F0EB;
        .totalAmountValue {
            color: $colorRed;
        }
    }
    .vatTax {
        // border-bottom: 0.5px solid $colorOffWhite;
        p {
            margin-bottom: 5px;
        }
    }
    .taxAmount {
        padding: 0px !important;
        width: 20%;
        color: $colorDarkGray;
    }
    .vatLocal {
        color: $colorDarkGray;
        font-size: 12px;
        margin-top: 11px;
    }
}

.no-border {
    border: 0;
}

.primaryFont {
    font-family: $fontFamilyPrimary;
}



.drop-ship-invoice-box{
    background: $colorWhite;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius:24px 24px 0 0;
    padding: 50px 50px;
    overflow: auto;
    position: relative;
    min-height: 400px;
    overflow-x: hidden;
    &.drop-ship-invoice-box--rounded{
        border-radius:24px 24px
    }
    &.drop-ship-invoice-box--scroll{
        max-height: 75vh;
    }
    .form-control {
        border: 1px solid $colorBlack;
        color: $colorBlack
    }
}

.drop-ship-invoice-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .customDraftWidth {
        width: 17%;
        padding-right: 0px;
    }
    .customAcceptWidth {
        width: 10%;
    }
    button {
        font-family: $fontFamilyPrimary;
        font-size: 12px;
        display: inline-block;
    }
    .exportInvoice {
        width: 19%;
        margin-bottom: 0px;
        .css-1s2u09g-control, .css-1pahdxg-control {
            font-family: $fontFamilyPrimary;
            text-transform: uppercase;
            font-size: 12px;
            min-height: 44px !important;
            .css-14el2xx-placeholder {
                color: $colorBlack !important;
            }
            .css-qc6sy-singleValue {
                color: $colorBlack;
            }
        }
        
    }
    #react-select-2-listbox {
        font-family: $fontFamilyPrimary;
        text-transform: uppercase;
        font-size: 12px;
    }
}

.drop-ship-inovice-card {
    background-color: $colorWhite;
    border: none;
    border-left: 3px solid $colorRed;
    border-radius: 0px;
    &.paymentDetails {
        border-left: 3px solid $colorLightGrey;
    }
    .ml-60 {
        margin-left: -60px;
    }
    .ml-20 {
        margin-left: 20px;
    }
    .ml-40 {
        margin-left: -40px;
    }
    .breakDownHeading {
        font-weight: 700;
    }
    .breakDownValue {
        p {
            margin-bottom: 0px;
        }
    }
}

.rejectPopup {
    &.error {
        textarea {
            border: 1px solid $colorRed;
        }
    }
    textarea {
        padding: 20px;
        border-radius: 20px;
        resize: none;
    }
    .tool {
        font-family: $fontFamilySecondary;
        text-transform: none;
        cursor: help;
        position: relative;
        margin-left: 3px;

        &:before, 
        &:after {
            left: 50%;
            opacity: 0;
            position: absolute;
            z-index: -100;
        }

        &:hover::before,
        &:focus::before,
        &:hover::after,
        &:focus::after {
            opacity: 1;
            transform: scale(1) translateY(0);
            z-index: 100; 
        }

        &:before {
            border-style: solid;
            border-width: 1em 0.75em 0 0.75em;
            border-color: #222 transparent transparent transparent;
            bottom: 100%;
            content: "";
            margin-left: -0.72em;
            transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
            transform:  scale(.6) translateY(-90%);
        } 

        &:hover::before,
        &:focus::before {
            transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
        }

        &:after {
            background: #222;
            border-radius: .25em;
            bottom: 150%;
            color: #fff;
            content: attr(data-tip);
            margin-left: -11em;
            padding: 1em;
            transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
            transform:  scale(.6) translateY(50%);  
            width: 23.5em;
        }

        &:hover::after,
        &:focus::after  {
            transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
        }
    }
}

.invoicePopup {
    h6 {
        font-family: 'HM Slussen Expanded Headline Bold';
        font-weight: 700;
    }
    .modal-footer {
        border-top: none;
    }
    .commentsData {
        background-color: $colorOffWhite;
        padding: 20px;
        border-radius: 20px;
        margin-top: 30px;
        white-space: pre-line;
    }
    .modal-content {
        padding: 70px;
        border-radius: 20px;
        border: none;
    }

    .modal-dialog {
        max-width: 800px;
    }
    
    .modal-body {
        margin-bottom: 20px;
    }
    .confirmText {
        p {
            margin-bottom: 0px;
        }
    }
}