@import "../../styles/variables.scss";
@import "../../components/Global/Text/index.scss";


.UpLoad-hero{
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
}

.UpLoad-heroText{
    max-width: 60%;
    color: $colorWhite; 
    @extend .Text-sm;
}

.UpLoad-heroCta{
    .Button{
        margin-right: 25px;
    }
    .Link{
        color: $colorWhite; 
    }
}

.Upload-ctaTop {
    top: 50px;
}

.no-border {
    border: 0;
}

.hmView {
    .cursor-pointer {
        cursor: pointer;
    }
    .ProductCard {
        background-color: $colorWhite;
    }
    .css-1s2u09g-control, .css-1pahdxg-control, .css-1insrsq-control{
        border-radius: 20px !important;
        border: 0.821px solid $colorDarkGray;
        padding: 0 6px 0 6px !important;
        background-color: transparent;
        min-height: 38px !important;
    }
    // .css-26l3qy-menu {
    //     max-height: 200px; 
    //     overflow: auto; 
    // }
    
    // .css-4ljt47-MenuList { 
    //     max-height: 200px; 
    //     overflow-y: auto; 
    // }
    .assetContainer {
        margin-top: 30px;
        border-top: 2px solid $colorWhite;
    }
    .acceptRejectIcon {
        display: flex;
        gap: 10px;
        margin-left: 40px;
    }
    
    .tooltipWrapper {
        position: relative;
        display: inline-block;
    }
    
    .tooltip {
        font-family: $fontFamilySecondary;
        visibility: hidden;
        width: 200px;
        background-color: $colorWhite;
        color: $colorDarkGray;
        text-align: center;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: -40px; 
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 0.3s;
    }
    
    .tooltip::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent $colorWhite transparent;
    }
    
    .tooltipWrapper:hover .tooltip {
        visibility: visible;
        opacity: 1;
    }    
}

.hmPopup {
    .close-button {
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 0; 
        background: none;
        border: none;
        cursor: pointer;
    }
    .modal-dialog {
        max-width: 350px !important;
    }  
}

.rejectReasonPopUp {
    h6 {
        font-family: 'HM Slussen Expanded Headline Bold';
        font-weight: 700;
    }
       
    .commentsData {
        background-color: $colorOffWhite;
        padding: 20px;
        border-radius: 20px;
        margin-top: 30px;
        white-space: pre-line;
    }
        
    .modal-dialog {
        max-width: 800px !important;
    }
        
    .modal-body {
        margin-bottom: 20px;
    }
    .modal-content {
        padding: 70px !important;
    }
    &.error {
        textarea {
            border: 1px solid $colorRed;
        }
    }
    textarea {
        padding: 20px;
        border-radius: 20px;
        resize: none;
    }
}