@import "../../../styles/variables.scss";
.users-table {
    .tool {
        font-size: $textSizeLarge !important;
        cursor: help;
        position: relative;
        margin-left: 3px;

        &:before, 
        &:after {
            left: 50%;
            opacity: 0;
            position: absolute;
            z-index: -100;
        }

        &:hover::before,
        &:focus::before,
        &:hover::after,
        &:focus::after {
            opacity: 1;
            transform: scale(1) translateY(0);
            z-index: 100; 
        }

        &:before {
            border-style: solid;
            border-width: 0em 0.75em 1em 0.75em;
            border-color: transparent transparent #222 transparent;
            bottom: -16px;
            content: "";
            left: 0;
            transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
            transform:  scale(.6) translateY(-90%);
        } 

        &:hover::before,
        &:focus::before {
            transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
        }

        &:after {
            background: #222;
            border-radius: .25em;
            top: 58px;
            white-space: normal;
            color: #fff;
            content: attr(data-tip);
            left: -165px;
            padding: 1em;
            transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
            transform:  scale(.6) translateY(50%);  
            pointer-events: none;
            width: 17.5em;
        }

        &:hover::after,
        &:focus::after  {
            transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
        }

        &:nth-child(3) {
            &:after {
                left: 0 !important;
            }
        }
    }
}