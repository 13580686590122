@import '../../styles/variables.scss';

.multiLevelDropdown {
    .dropdown {
        border-radius: 4px;
        background-color: $colorWhite;
        border-style: solid;
        border-width: 1px;
        border-color: $colorBlack;
        &.disabled {
            background-color: #f2f2f2;
            border-color: #222 !important;
            border-radius: 40px !important;
            min-height: 51px !important;
            padding: 0 12px 0 20px !important;
            font-family: $fontFamilyPrimary;
            font-size: $textSizeLarge;
            font-style: normal;
            font-weight: 400;
            border-style: solid;
            border-width: 1px;
            cursor: default;
            position: relative;
            transition: all 100ms ease 0s;
            box-sizing: border-box;
            outline: 0px !important;
            .dropdown-header {
                padding: 15px 10px 10px 10px !important;
                cursor: default;
            }
        }
    }

    .dropdown-header {
        padding: 15px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .dropdown-body {
        display: none;
        overflow: auto;
        width: 240px;
        max-height: 300px;
        white-space: nowrap;
        ul {
            list-style: none;
            padding-left: 20px;
            &:first-child {
                padding: 10px;
                margin-bottom: 0;
            }
            .form-check-input {
                margin-left: 5px; 
                margin-right: 5px;
            }
            li {
                padding-top: 2px;
            }
        }
        &::-webkit-scrollbar {
            height: 6px;              
            width: 6px;      
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: $colorStone3; 
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $colorGray; 
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $colorDarkGray; 
        }
    }

    .dropdown-body.open {
        display: block;
        position: absolute;
        width: -webkit-fill-available;
        background-color: $colorWhite;
        margin-top: 7px;
        border-radius: 4px;
        border: 1px solid $colorStone1;
    }

    .dropdown-item {
        padding: 10px;
    }

    .dropdown-item:hover {
        cursor: pointer;
    }

    .dropdown-item-dot {
        opacity: 0;
        color: #91A5BE;
        transition: all .2s ease-in-out;
    }

    .dropdown-item-dot.selected {
        opacity: 1;
    }

    // .icon {
    //     font-size: 13px;
    //     color: #91A5BE;
    //     transform: rotate(0deg);
    //     transition: all .2s ease-in-out;
    // }

    // .icon.open {
    //     transform: rotate(90deg);
    // }

    .icon-Minus {
        margin-left: 2px;
        &:before {
            content:  url('../../assets//images/minus-icon.png');
        }
    }

    .icon-Plus {
        margin-left: 2px;
        &:before {
            content:  url('../../assets//images/plus-icon.png');
        }
    }
}