@import '../../styles/variables.scss';
.ProductCard{
    background-color: $colorOffWhite;
    box-shadow: 0px 5.35544px 10.7109px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    min-height: 163px;
   // overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .checkbox-container {
        position: relative;
    }
      
    .hidden-checkbox {
        position: absolute;
        opacity: 0;
    }
    
    .checkbox-label {
        display: inline-block;
        width: 10px; 
        height: 10px;
        cursor: pointer;
    }
    
    .checkbox-custom {
        display: inline-block;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/icons/unchecked.png');
        background-size: cover;
        background-position: center; 
        cursor: pointer;
    }
    
    .hidden-checkbox:checked + .checkbox-label .checkbox-custom {
        background-image: url('../../assets/images/icons/checked.png');
        cursor: pointer;
    }
    
    [data-title]:before {
        top: -1px; 
        left: 50%; 
        transform: translateX(-50%);
        border-top: 6px solid $colorWhite;
        z-index: 999999 !important;
    }

    [data-title]:after {
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 0px;
        top: -31px;
        left: 50%; 
        transform: translateX(-50%);
        background-color: $colorWhite;
        color: $colorBlack;
    }
    .ProductCard-expand {
        position: absolute;
        top: 2px;
        right: 25px;
        cursor: pointer;
    }
    .ProductCard-download {
        position: absolute;
        top: 6px;
        right: 6px;
        cursor: pointer;
    }
    .autoDelete {
        position: absolute;
        bottom: -7px; 
        right: 0px;  
        cursor: pointer;

        .tooltip {
            font-family: $fontFamilySecondary;
            visibility: hidden;
            width: 170px;
            background-color: $colorWhite;
            color: $colorDarkGray;
            text-align: left;
            padding: 10px;
            position: absolute;
            z-index: 1;
            bottom: -90px; 
            left: -70px;
            transform: translateX(-50%);
            opacity: 0;
            transition: opacity 0.3s;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        }
        
        .tooltip::after {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 162px;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent $colorWhite transparent;
        }
          
        &:hover .tooltip {
            visibility: visible;
            opacity: 1;
        }
    }
        
    .rejectReasonValue {
        font-weight: 400;
        color: $colorRed;
    }
    .rejectReason {
        color: $colorRed;
    }
    [data-tooltip] {
        position: absolute;
        top: -16px;
        right: -12px;
        cursor: pointer;
        text-align: center;
    }
    
    [data-tooltip]:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent; 
        border-right: 6px solid transparent; 
        border-bottom: 6px solid transparent;
        border-top: 6px solid $colorWhite;
        top: -9px;
        left: calc(50% - 8px); 
        opacity: 0;
        transition: opacity 0.3s;
        visibility: hidden;
        z-index: 999999;
    }

    [data-tooltip]:after {
        content: attr(data-tooltip);
        position: absolute;
        background: $colorWhite;
        color: $colorDarkGray;
        padding: 10px;
        white-space: pre-wrap; 
        overflow: hidden;
        display: block;
        -webkit-box-orient: vertical;
        opacity: 0;
        transition: opacity 0.3s;
        bottom: calc(100% + 9px); 
        left: 50%;
        transform: translateX(-50%);
        width: 150px;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1); 
        font-size: 12px;
        font-style: italic;
        z-index: 99999;
        visibility: hidden;
        overflow: auto;
        max-height: 150px;
    }
    
    [data-tooltip]:hover:after,
    [data-tooltip]:hover:before {
        opacity: 1;
        transition: all 0.1s ease 0.5s;
        visibility: visible;
    }

    .commentIcon {
        width: 25px;
        height: 25px;
    }
}

.approved, .downloaded {
    width: 100%;
    float: left;
    margin-bottom: 5px;
    font-size: $textSizeLarge;
    color: #8DD5B9;

}

.zoom-asset {
    display: block;
    position: fixed;
    z-index: 1000;
    padding-top: 60px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: rgba(0,0,0,.6);

    .close {
        position: absolute;
        top: 15px;
        right: 20px;
        color: #fff;
        font-size: 36px;
        font-weight: 700;
        cursor: pointer;
    }

    img {
        margin: auto;
        display: block;
        width: 80%;
        max-width: 960px;
        border: 2px solid #ddd;
        border-radius: 4px;
    }
}

.isExpand {
    cursor: pointer;
}

.ProductCard-delete{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 25px; 
    height: 25px; 
    cursor: pointer;
    background-image: url('../../assets/images/icons/removeIcon.png'); 
    transition: background-image 0.3s ease;
    &:hover{
        background-image: url('../../assets/images/icons/removeHoverIcon.png');
    }
}

.ProductCard-image{
    padding: 10px 20px;
    max-width: 85%;
    max-height: 100px;
    min-height: 100px;
    margin: auto;
    display:block; 
    // cursor: pointer;
}

.ProductCard-checkbox{
    position: absolute;
    top:10px;
    right: 10px;
    .Checkbox{
        .Checkbox-label{
            font-size: 0;
        }
    }
}

.ProductCard-checkbox {
    .checkbox-container {
      display: flex;
      align-items: center;
  
      /* Style for the hidden checkbox */
      .Checkbox-input {
        position: absolute;
        opacity: 0;
        cursor: pointer; /* Ensure cursor changes on hover */
      }
  
      /* Style for the label */
      .checkbox-label {
        cursor: pointer; /* Make the label area clickable */
        display: flex;
        align-items: center;
      }
  
      /* Style for the custom checkbox */
      .checkbox-custom {
    
        background-image: url('../../assets/images/icons/unchecked.png');
        background-size: cover;
        background-position: center; 
        display: inline-block;
        position: relative;
        flex-shrink: 0; /* Prevent the checkbox from growing */
        margin-right: 5px; /* Adjust spacing between custom checkbox and label */
      }
  
      /* Custom checkbox checked state */
      .Checkbox-input:checked + .checkbox-label .checkbox-custom::after {
      
        display: block;
        background-image: url('../../assets/images/icons/checked.png');
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); /* Center the checkmark */
      }
    }
  }

.ProductCard-content{
    // cursor: pointer;
    padding: 10px;
    justify-content: space-between;
    p {
        margin-bottom: 0px;
        font-size: 12px;
    }
    
    .text-ellipsis {
        max-width: 100%;
        cursor: pointer;
        position: relative;
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            max-width: 100%; 
            vertical-align: bottom;
        }
    }
    
    .text-ellipsis:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent; 
        border-right: 6px solid transparent; 
        border-bottom: 6px solid transparent;
        border-top: 6px solid $colorWhite;
        top: -5px;
        left: calc(50% - 8px); 
        opacity: 0;
        transition: opacity 0.3s;
        visibility: hidden;
        z-index: 999999;
    }

    .text-ellipsis:after {
        content: attr(data-tooltipText);
        position: absolute;
        background: $colorWhite;
        color: $colorDarkGray;
        padding: 10px;
        white-space: pre-wrap; 
        overflow: hidden;
        display: block;
        -webkit-box-orient: vertical;
        opacity: 0;
        transition: opacity 0.3s;
        bottom: calc(100% + 5px); 
        left: 50%;
        transform: translateX(-50%);
        width: 200px;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1); 
        font-size: 12px;
        // font-style: italic;
        z-index: 99999;
        visibility: hidden;
        overflow: auto;
        max-height: 150px;
        text-align: center;
    }
    
    .text-ellipsis:hover:after,
    .text-ellipsis:hover:before {
        opacity: 1;
        transition: all 0.1s ease 0.5s;
        visibility: visible;
    }
}

.ProductCard-heading{
    font-size: $textSizeLarge;
    line-height: 130%;
    letter-spacing: 0.015em;
    word-break: break-all;
}

.ProductCard-size{
    display: block;
    font-size: $textSizeSmall;
    line-height: 160%;
    letter-spacing: 0.02em;
    font-weight: 100;
}

.ProductCard-sizeInvalid{
    // display: none;
    color: $colorRed;
    font-size: $textSizeXSmall;
    // position: relative;
    width: 100%;
    i{
        font-size: 10px;
        margin-right: 5px;
    }
}

.ProductCard-history{
    color: $colorTextHint;
    font-size: $textSizeXSmall;
}

.ProductCard-bottom{
    display: flex;
    padding:10px 20px 25px;
    justify-content: space-between;
}

.ProductCard{
    &.is-invalid{
        box-shadow: 0px 0px 10px rgba(255, 2, 2, 0.25);
        .ProductCard-sizeInvalid{
            display: block;
        }
        .ProductCard-size{
            display: none;
        }

        .ProductCard-message{
            display: block;
        }

    }
}

.ProductCard-message{
    position: absolute;
    width: 225px;
    top: 61%;
    left: 100%;
    // display: none;
    background: $colorRed;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 10px;
    z-index: 5;
   
    &::after {
        content: "";
        position: absolute;
       top: 20%;
       left: -5px;
       width: 0; 
       height: 0; 
       border-top: 10px solid transparent;
       border-bottom: 10px solid transparent; 
       border-right:10px solid $colorRed;
    }
}

.ProductCard-messageText{
    font-size: $textSizeXSmall;
    color: $colorWhite;
    margin-bottom: 0;
}

.expandPopup {
    .modal-footer {
        border-top: none;
    }
    .modal-dialog {
        max-width: 700px;
    }
    .modal-content {
        padding: 50px;
        border-radius: 20px;
        border: none;
    }
    .modal-body {
        .modal-content {
            padding: 0px;
        }
    }
    .ProductCard-minimize {
        position: absolute;
        top: -35px;
        right: -35px;
        cursor: pointer;
    }
    .reject-icon {
        width: 20px;
        height: 20px;
    }
    .imageContainer {
        text-align: center;
    }
    p {
        margin-bottom: 5px;
    }
    .rejectReasonText {
        font-style: italic;
        color: $colorRed;
        margin-top: 20px;
    }
    .fileNameText {
        color: $colorDarkGray;
        margin-top: 10px;
    }
}
