@import '../../App';
@import "../../styles/variables.scss";


.Login-page {
    background-color: $colorOffWhite;
    min-height: 100vh;
    width: 100%;

    .hmGroup {
        font-size: 19px;
        color: $colorBlack;
        margin-bottom: 0px;
    }

    .container, .container-xl{
        padding: 0px;
        margin: 0px;
        max-width: 99%;
    }
    
    .landingImage {
        padding: 0px;
        text-align: right;
        img {
            max-height: 100vh;
        }
    }

    .Indent-hero {
        padding-left: 15rem;
        .Indent-heroWrapper {
            margin-left: 0px;
        }
    }

    .gallery  {
        height: 100vh;
        overflow: hidden;
        padding: 0 6px;

    
        .Gallery-animation{
            width: 100%;
            background-size: cover;
            height: 100vh;
   
            &:hover{
                animation-play-state: paused;
               }
        }
        .gallery-left{
            background-image: url('../../assets/images/leftImage.png') ;
            animation: move-down 285s  linear infinite alternate;
           
    
        }
        .gallery-right{
            background-image: url('../../assets/images/rightImage.png') ;
            animation: move-up 335s  linear infinite alternate;
        }
    }
}

@keyframes move-down {
    from {
        background-position: 0 0;
      }
      to {
        background-position: 0 -500%;
      }
  }

  @keyframes move-up {
    from {
        background-position: 0 -500%;
      }
      to {
        background-position: 0 0;
      }
  }