@import "../../styles/variables.scss";

.partner-profile {
    background-color: $colorOffWhite;
    .fontFamilySecondary {
        button {
            font-family: $fontFamilySecondary;
            text-transform: none;
        }
    }
    .searchBox {
        padding: 0 12px 0 20px;
        min-height: 51px;   
        border-radius: 40px;
    }
    
    .right-inner-addon i {
        padding: 17px 17px;
    }
    .icon-edit-view {
        cursor: pointer;
    }
    .manage-button {
        cursor: pointer;
        background: transparent;
        i{
            margin-right: 8px;
        }
        margin-left: 20px;
        &:hover{
            color: $colorDarkGray;
        }
        &:disabled{
            &:hover{
                color: rgba(16, 16, 16, 0.3);
            }
        }
    }
    .delete-partner {
        float: right;
    }
    .Profile-hero{
        color: $colorBlack;
        .dropdown-toggle{
            color: $colorBlack;
        }
    }

    .filter-section {
        margin-bottom: 15px;
        #dropdown-basic {
            padding-left: 0;
        }
        a {
            display: inline-block;
            margin-right: 20px;
            font-family: $fontFamilyPrimary;
            font-style: normal;
            font-weight: normal;
            font-size: $textSizeLarge;
            line-height: 120%;
            letter-spacing: 0.03em;
            text-transform: uppercase;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .dropdown {
        button {
            font-family: $fontFamilyPrimary;
            font-style: normal;
            font-weight: normal;
            font-size: $textSizeLarge;
            line-height: 120%;
    
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: $colorBlack;
        }

        .dropdown-menu {
            padding: 0;
        }

        .dropdown-toggle {
            &::after {
                border: 0;
            }
        }
        
        .dropdown-item {
            font-family: $fontFamilySecondary;
            font-style: normal;
            font-weight: normal;
            font-size: $textSizeLarge;
            line-height: 160%;
            text-transform: initial;
            letter-spacing: 0.02em;
            color: $colorBlack;

            &:hover {
                background-color: $colorOffWhite;
            }

            &:first-child {
                padding-top: 10px;
            }
            &:last-child {
                padding-bottom: 10px;
            }
        }
    }

    .accordion {
        .editIcon{
            margin-left: 7px;
        }

        .manageUser{
            margin-left: 50px;
        }

        .emptyPartner{
            border-color: $colorInvalid;
        }

        .users-table {
            margin-top: 0;
            padding: 0px 20px;
        }

        .add-entity {
            border-top: 0.5px solid #D0D0D0;
            padding: 10px 30px 10px 30px;
            text-align: left;
    
            span {
                font-family: $fontFamilyPrimary;
                font-style: normal;
                font-weight: normal;
                font-size: $textSizeLarge;
                line-height: 120%;            
                letter-spacing: -0.01em;
                color: $colorBlack;
            }
            .UploadFooter-delete {
                margin-right: 0;
                &:hover {
                    span {
                        color: $colorWhite;
                    }
                }
            }
        }

        .accordion-item:last-of-type .accordion-button.collapsed {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        .accordion-item {
            background: #FFFFFF;
            border: 0;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            margin-bottom: 10px;
        }

        .accordion-button {
            border-radius: 8px;
            font-family: $fontFamilySecondary;
            text-transform: none;
            font-style: normal;
            font-weight: normal;
            font-size: $textSizeLarge;
            line-height: 140%;
            letter-spacing: 0.015em;
            color: $colorBlack;

            input.inputPartnerName {
                width: 30%;
                display: inline-block;
            }

            span.entities {
                font-family: $fontFamilySecondary;
                font-style: normal;
                font-weight: normal;
                font-size: $textSizeLarge;
                line-height: 160%;
                letter-spacing: 0.02em;
                color: #53565A;
                position: absolute;
                right: 50px;
            }

            .collapsed {
                border-radius: 8px;
            }

            img {
                border-radius: 50%;
                margin-right: 14px;
            }

            &:not(.collapsed) {
                background-color: #ffffff;
                box-shadow: none;

                &::after {
                    background-image:  url('../../assets//images/minus-icon.png');
                }
            }
            &::after {
                width: 14px;
                height: 14px;
                background-image: url('../../assets//images/plus-icon.png');
                background-size: 14px;
            }
        }

        .accordion-body {
            padding: 0;
        }
    }
    
}
