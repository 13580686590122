@import "../../../styles/variables.scss";
.Article {
  font-family: $fontFamilySecondary;
  margin-top: 35px;
  .Preamble {
    font-weight: 600;
  }

  b {
    font-weight: 600;
  }

  ol,
  ul {
    padding-left: 1rem;
    li {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  label {
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: $textSizeLarge;
    margin-bottom: 8px;
  }

  img{
    transition: .3s ease-in-out;
  }
}

.Article-sectionHeading {
  text-align: center;
  margin-bottom: 50px !important;
}

.Article-content {
  margin-bottom: 20px;
  &--size1of1 {
    .Heading {
      margin-bottom: 20px;
    }

    .Preamble {
      margin-bottom: 50px;
    }

    .Button {
      margin-bottom: 50px;
    }
  }
  &--size1of3 {
    // width: 33%;
    .Article-image {
      margin-bottom: 20px;
      text-align: center;
      min-height: 100px;
      // max-height: 180px;
      overflow: hidden;
    vertical-align: middle;
      img{
        &:hover{
          // transform: scale(1.2);
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.10);
        }
      }
    }
    .Heading {
      margin-bottom: 10px;
    }
    .Heading,
    .ArticleText {
      padding-right: 2rem;
      margin-bottom: 20px;
    }
  }
  &--size1of2 {
    .Article-image {
      height: 100%;
      align-items: center;
      display: flex;
     
      &:hover{
        img{
        transform: scale(1.3);
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.10);
      }
      }
    }

    &.Article-content--right {
      .row {
        flex-direction: row-reverse;
      }
    }
    .Article-image{
      align-items: center;
      display: flex;
      height: 100%;
    }
  }
}

.Article-content--media {
  text-align: center;
  .Heading {
    margin-bottom: 20px;
  }
  P {
    margin-bottom: 40px;
  }
  img  {
    width: 100%;
  }
  // video {
  //   max-width: 30%;
  // }

  img{
    &:hover{
      // transform: scale(1.1);
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.30);
    }
  }

}

.video-block {
  .Article-content--media {
    text-align: left;
    .Article-content--mediaContent {
      max-width: none;
    }
  }
}



.Article-content--mediaContent {
  max-width: 655px;
  margin: 0 auto;
}

//
.Article-content--multiImage {
  .multiImgeWrapper {
    // background: $colorHome2;
    border-radius: 26px;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  img {
    transition: 0.3s ease-in-out;
    border-radius: 20px;
    max-width: 33%;
    padding: 10px;
    align-self: center;
    &:hover {
      // transform: scale(1.3);
    }
  }
  .Heading {
    margin-bottom: 20px;
  }
}

//
.Article-content--download {
  .Heading {
    margin-bottom: 20px;
  }
}
.Article-docHeading {
  font-size: $textSizeLarge;
  margin-bottom: 10px;
}
.Article-docDescription {
  margin-bottom: 2px;
}

.Article-docLink {
  
  display: block;
  margin-bottom: 12px;
  word-break: break-all;
  color: $colorDarkGray !important;
  &:hover {
    color: $colorBlack !important;
    span {
      text-decoration: underline;
    }
  }
  i {
    margin-right: 6px;
  }
}

.Article-button {
}

.Article-status {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $fontFamilyIcon;
  font-size: $textSizeLarge;
  position: absolute;
  right: 15px;
  bottom: 10px;
  &--done {
    background: $colorValid;
    color: $colorText;
    &:before {
      content: "\e925";
    }
  }
}

.ArticleAccordion {
  .ArticleAccordion-header {
    &:hover {
    }
    .accordion-button {
      transition: 0.3s ease-in-out;
      color: $colorPrimaryBlack;
      font-size: $textSizeLarge;
      font-weight: 600;
      border: 1px solid $colorBlack;
      text-transform: uppercase;
      padding-left: 40px;
      &:after {
        font-family: $fontFamilyIcon;
        content: "\e901";
      }
      &:focus {
        box-shadow: none;
      }
      &:hover {
        color: $colorBlack;
        border-color: $colorBlack;
        &:after {
          font-weight: bold;
        }
      }
      &[aria-expanded="true"]{
        border-bottom: 1px solid $colorWhite;
        // &:hover{
        //   border-color: $colorBlack;
        // }
      }
    }
  }
}

.ArticleAccordion-content {
  border-right:  1px solid  $colorBlack;
  border-bottom: 1px solid  $colorBlack;
  border-left: 1px solid  $colorBlack;
  background: $colorWhite;
  padding: 25px 40px 30px;
  border-radius: 0 0 20px 20px;
  .Article-content {
    margin-bottom: 0;
  }
  .col-lg-5 {
    width: 50%;
  }
}
