@import "../../../styles/variables.scss";
.Indent-hero{
    display: flex;
        flex-direction: column;
        padding-left: 4rem;
        height: 100%;
        justify-content: center;

        .Heading{
            color: $colorBlack;
            margin-bottom: 25px;
        }

        p{
            font-family: $fontFamilySecondary;
            margin-bottom: 2rem;
            color: $colorWhite;
            font-size: $textSizeLarge;
            line-height: 160%;
        }

        .Indent-heroWrapper{
            // margin-left: 80px;
        }
}