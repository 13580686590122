@import "../../../styles/variables.scss";
.Breadcrumb{
    padding: 0;
}

.Breadcrumb-item{
    display: inline-block;
    list-style: none;
    font-size: $textSizeLarge;
    color: $colorBlack;
    text-transform: uppercase;
}

.Breadcrumb-link{
    display: block;
    color: $colorBlack;
    &:after{
        content: "\e903";
        margin: 0 5px;
        font-family: $fontFamilyIcon;
        font-size: $textSizeLarge;
    }
}
.Breadcrumb-current{
    font-weight: 600;
}