.addEntity {
    .addUser-popup {
        &--body {
            padding: 40px 27px 0;
            min-height: calc(100vh - 146px);
            max-height: calc(100vh - 146px);
            span {
                margin-top: 15px;
            }
        }
    }
    select {
        -webkit-appearance: auto !important;
        appearance: auto !important;
    }
    .tool {
        margin-left: 0px !important;
        float: right;
        top: 37px;
        left: 21px;
        &:after {
            margin-left: -22.7em !important;
        } 
    }
    select[name=integrationTypeId], input[name=shopId], input[name=spurId]{
        margin-bottom: 0 !important;
    }
}