@import '../../App';
@import "../../styles/variables.scss";

.welcome-page {
    .css-2613qy-menu {
        z-index: 1000 !important;
        position: relative !important;
    }
    
    width: 100%;
    height: 100vh;

    .portfolioBrand {
        font-size: 24px;
    }

    .portfolioBrandSelect {
        width: 33%;
    }

    .Indent-hero p {
        color: $colorPrimaryBlack;
    }

    .bg-area {
        background: $colorOffWhite;
        width: 100%;
        height: 100vh;
    }

   .container-md, .row{
        height: 100%;
    }
  
}
