// - Text Sizes -
$textSizeXXSmall: 10px;
$textSizeXSmall: 12px;
$textSizeSmall: 13px;
$textSizeMedium: 14px;
$textSizeLarge:  15px;
$textSizeXLarge: 18px;
$textSizeXXLarge: 20px;
$textSizeXXXLarge: 22px;

 
//Fonts
$fontFamilyPrimary: HM Slussen Extended, Georgia, Times New Roman, Times, serif;
$fontFamilySecondary: HM Serif, Arial, Helvetica, sans-serif;
$fontFamilyIcon: hm-icon;
$fontHMSlussenExpandedBold: HM Slussen Expanded Bold, Georgia, Times New Roman, Times, serif;

// Colors
$colorPrimaryRed: #E50010; 
$colorPrimaryWhite: #FFFFFF;
$colorPrimaryGreen: #CEEBCC;
$colorPrimaryBlack: #000000;

//Primary colors
$colorBlack: #000;
// $colorPrimaryBlack: #222; 
$colorWhite: #fff; 
$colorOffWhite: #F0F0EB;
$colorLightGrey: #969691;

//Category colors
$colorBrown: #8C5032; 
$colorHacki: #998354; 
$colorCorduroy: #63786A; 
$colorBlue: #45678C; 
$colorCopperRose: #9B6566; 
$colorPink: #DEB2A9; 
$colorOrchid: #D8A68C; 

//
$colorRed: #E50010;

//sections / Backgrounds
$colorSnow: #FAF9F8; 
$colorDawnPink: #F1EBDF; 
$colorFoggyGray: #CDC7BA; 
$colorLightBlue: #B9C8D2;

//Neutral greys
$colorGray: #5E6470;
$colorDarkGray: #646464;
// $colorGray: #97999B;
$colorStone1: #BCBCBC;
$colorStone2: #D0D0D0;
$colorStone3: #E4E4E4;

//Text color (inherited from Black)
$colorTextBlack: $colorBlack;
$colorTextSecondery: rgba($colorTextBlack, 0.65);
$colorTextDisabled: rgba($colorTextBlack, 0.35);
$colorTextHint: rgba($colorTextBlack, 0.35);
$colorText: $colorTextBlack;
$colorTextValid: #00853D;
$colorTextInvalid: #970012;

$colorLink: $colorTextBlack;
$colorLinkHover: $colorTextBlack;

//Grid
$colorTableHead: #D8A68C;
$colorTableHeadBorder: #9B6566;

//Context (Sale)
$colorBaseSale: #C9002E;

//Warning/Error action color
$colorInvalid: #FF7777;
$colorWarning: #FFD1A1;

//Sucess action color
$colorValid: #8DD5B9;

//Info
$colorInfo: #93CDFF;

//Segments colors
$colorHome1: $colorCorduroy;
$colorHome2: $colorOrchid;

$colorBeauty1: $colorBlue;
$colorBeauty2: $colorPink;

$colorFashion1: $colorBlue;
$colorFashion2: $colorLightBlue;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);