.addUser-popup--body .tool {
    cursor: help;
    position: relative;
    margin-left: 3px;

    &:before, 
    &:after {
        left: 50%;
        opacity: 0;
        position: absolute;
        z-index: -100;
    }

    &:hover::before,
    &:focus::before,
    &:hover::after,
    &:focus::after {
        opacity: 1;
        transform: scale(1) translateY(0);
        z-index: 100; 
    }

    &:before {
        border-style: solid;
        border-width: 1em 0.75em 0 0.75em;
        border-color: #222 transparent transparent transparent;
        bottom: 100%;
        content: "";
        margin-left: -0.72em;
        transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
        transform:  scale(.6) translateY(-90%);
    } 

    &:hover::before,
    &:focus::before {
        transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
    }

    &:after {
        background: #222;
        border-radius: .25em;
        bottom: 150%;
        color: #fff;
        content: attr(data-tip);
        margin-left: -11em;
        padding: 1em;
        transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
        transform:  scale(.6) translateY(50%);  
        width: 23.5em;
    }

    &:hover::after,
    &:focus::after  {
        transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
    }
}













