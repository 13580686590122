@import '../../styles/variables.scss';
.Hero{
    
    .Heading,
    .Link,
    p,
    a,
    span {
      color: $colorBlack;
    }
    color: $colorBlack;
    .Heading {
        text-transform: uppercase;
    }
    .container-xl {
        padding: 0 0 0 30px;
    }
}

.noHeroImage{
    padding-top: 30px;
    padding-bottom: 30px;
}

.Hero-content-container {
    display: flex;
    padding: 0 0 0 60px;
    // justify-content: center;
    // align-items: center;
}


.Hero-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 10px;
}

.Hero-content-black{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 10px;
    p{
        color: black
    }
    .Heading--h2{
        color: black
    }
    .Hero-indent{
        color: black
    }
}

.Hero-heading{
   margin-bottom: 30px;
}

.Hero-indent{
    display: block;
    // padding-left: 12%;
}

.Hero-preamable{
    font-size: $textSizeXXXLarge;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 32px;
    font-family: $fontFamilySecondary;
}

.Hero-cta{
    .Button{
        margin-right: 26px;
    }
}

.Hero-multiImageWrapper{
    position: relative;   
    .Hero-multiImage{
        max-width: 100%;
    }
    .Hero-multiImage1{
        top: 0px
    }
    .Hero-multiImage2{
        top: -48px;
        left: 160px;    
    }
    .Hero-multiImage3{
    top: 10px;
    left: 20px;
  
}
    .Hero-multiImage4{
        top: 1px;
        left: 40px;
        display: none;
    }
}

// Overlaped
.Hero-overlap{
    position: relative;
    display: flex;
    .Hero-content{
        z-index: 1;
        width: 100%;
        min-height: 270px;
    }

    .Hero-heading{padding-left: 6rem;}
    .Hero-preamable{
        max-width: 500px;
    }
}

.Hero-imageOverlap{
    position: absolute;
    right: 3rem;
    z-index: 0;

    img{
        max-width: 450px;
        border-radius: 22px;
    }
}